<template>
    <b-form>
        <b-row>
            <b-col>
                <b-form-group
                    :invalid-feedback="invalidEmailFeedback"
                    :state="isEmailValid"
                    :label="$t('resetPassword.email')"
                    label-for="id_email"
                    :description="$t('resetPassword.emailDescription')"
                >
                    <b-form-input id="id_email" type="text" v-model="email" required :state="isEmailValid"/>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <b-button @click.prevent="send" type="submit" :disabled="!isValid" variant="success">{{ $t("button.send") | uppercase }}</b-button>
            </b-col>
        </b-row>
    </b-form>
</template>


<script>
import { CONFIG } from "../config.js"

export default {
    data: function() {
        return {
            email: null,
            emailChanged: false
        }
    },
    watch: {
        email: function(v) {
            this.emailChanged = (v !== null);
        }
    },
    computed: {
        emailRegex: function() {
            return new RegExp("^(([^<>()\\[\\]\\.,;:\\s@\"]+(\\.[^<>()\\[\\]\\.,;:\\s@\"]+)*)|(\".+\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$")
        },
        invalidEmailFeedback: function() {
            if (!this.emailChanged) {
                return '';
            }

            if (!this.email) {
                return this.$t('resetPassword.noEmailError');
            } else if (!this.emailRegex.test(this.email)) {
                return this.$t('resetPassword.invalidEmailError');
            }

            return '';
        },
        isEmailValid: function() {
            if (!this.emailChanged) {
                return null;
            }

            return this.invalidEmailFeedback == '';
        },
        isValid: function() {
            return this.isEmailValid
        }
    },
    methods: {
        send: function() {
            var self = this;

            this.$request("post", CONFIG.api.endpoints.forgottenPassword,
                function() {
                    self.$store.dispatch('eraseLoggedUserSession')
                    self.$ebus.$emit("msg:new", {type: "success-modal",
                        data: {
                            text: self.$t("resetPassword.requestSent"),
                            onclick: () => self.$router.push("/login")
                        }})

                },
                {
                    data:
                    {
                        "login_": this.email
                    },
                    headers: {
                        'Authorization': "Bearer " + CONFIG.resetPasswordToken
                    }
                }
            )
        }
    },
    filters: {
        uppercase: function(v) {
            return v.toUpperCase();
        }
    }
};
</script>


<style scoped lang="scss">

</style>
