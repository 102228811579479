<template>
    <b-form>
        <b-container>
            <b-form-group
                :invalid-feedback="invalidPasswordFeedback"
                :state="isPasswordValid"
                :label="$t('resetPassword.password')"
                label-for="id_password"
            >
                <b-form-input id="id_password" type="password" v-model="password" required :state="isPasswordValid"/>
            </b-form-group>

            <b-form-group
                :invalid-feedback="invalidRepeatFeedback"
                :state="isRepeatValid"
                :label="$t('resetPassword.repeat')"
                label-for="id_repeat"
            >
                <b-form-input id="id_repeat" type="password" v-model="repeat" required :state="isRepeatValid"/>
            </b-form-group>
        </b-container>

        <b-container>
                <button @click.prevent="reset" class="btn btn-success" type="submit" :disabled="!isValid">{{ $t("button.save") | uppercase }}</button>
        </b-container>
    </b-form>
</template>


<script>
import { CONFIG } from "../config.js"

export default {
    props: ["token"],
    data: function() {
        return {
            password: null,
            repeat: null,
            passwordChanged: false,
            repeatChanged: false
        }
    },
    watch: {
        password: function(v) {
            this.passwordChanged = (v !== null);
        },
        repeat: function(v) {
            this.repeatChanged = (v !== null);
        }
    },
    computed: {
        invalidPasswordFeedback: function() {
            if (!this.passwordChanged) {
                return '';
            }

            if (!this.password) {
                return this.$t('resetPassword.noPasswordError');
            }

            return '';
        },
        isPasswordValid: function() {
            if (!this.passwordChanged) {
                return null;
            }

            return this.invalidPasswordFeedback == '';
        },
        invalidRepeatFeedback: function() {
            if (!this.repeatChanged) {
                return '';
            }

            if (!this.repeat) {
                return this.$t('resetPassword.noPasswordError');
            } else if (this.repeat != this.password) {
                return this.$t('resetPassword.notEqualPasswordsError');
            }

            return '';
        },
        isRepeatValid: function() {
            if (!this.repeatChanged) {
                return null;
            }

            return this.invalidRepeatFeedback == '';
        },
        isValid: function() {
            return this.isPasswordValid && this.isRepeatValid;
        }
    },
    methods: {
        reset: function() {
            if (!this.isValid) {
                return;
            }

            var self = this;

            this.$request("post", CONFIG.api.endpoints.resetPassword,
                function() {
                    self.$ebus.$emit("msg:new",
                        {
                            type: "success-modal",
                            data: {
                                text: self.$t("resetPassword.success"),
                                variant: "success",
                                onclick: () => self.$router.push("/login")
                            }
                        }
                    )
                },
                {
                    data: {
                        "new_password_": this.password,
                        "new_password_confirmation_": this.repeat
                    },
                    headers: {
                        'Authorization': "Bearer " + this.$route.params.token
                    }
                }
            )
        }
    },
    filters: {
        uppercase: function(v) {
            return v.toUpperCase();
        }
    }
};
</script>


<style scoped lang="scss">

</style>
