import Vue from 'vue';
import Vuex from 'vuex';
import {
    CONFIG
} from './config.js';

Vue.use(Vuex);


const store = new Vuex.Store({
    state: {
        shifts: [],
        shiftBreaks : [],
        orders : [],
        token: null,
        tokenExpiresIn: null,
        tokenExpiredBackwardRoute: null,
        previousRoute: null,
        listFilter: {
            term: null,
            from: null,
            to: null,
            departments: [],
            orderBy : "shift_date.asc" // shift_date.desc, shift_date.asc, person_name
        },
        searchFormInitDone: false,
        availableDepartments: []
    },
    actions: {},
    mutations: {
        setShifts(state, shifts) {
            state.shifts = shifts
        },
        setToken(state, token) {
            state.token = token;
            if (token) {
                localStorage.token = token;
            } else {
                localStorage.removeItem("token")
            }
        },
        setTokenExpiresIn(state, expires) {
            state.tokenExpiresIn = expires;
            if (expires) {
                localStorage.expires = expires;
            } else {
                localStorage.removeItem("expires")
            }
        },
        setTokenExpiredBackwardRoute(state, route) {
            state.tokenExpiredBackwardRoute = route != '/login' ? route : CONFIG.afterLoginRoute;
        },
        updateShiftById(state, {
            id,
            data
        }) {
            Object.assign(this.getters.getShiftById(id), data);
        },
        setPreviousRoute(state, route) {
            state.previousRoute = route;
        },
        addShift(state, shift) {
            state.shifts.push(shift)
        },
        setListFilter(state, filter) {
            state.listFilter = filter
        },
        setAvailableDepartments(state, departments) {
            state.availableDepartments = departments
        },
        updateListFilter(state, patch) {
            state.listFilter = Object.assign({}, state.listFilter, patch)
        },
        setSearchFormInitDone(state, isDone) {
            state.searchFormInitDone = isDone
        },
        setShiftBreaks(state, breaks) {
            state.shiftBreaks = breaks
        },
        setOrders(state, orders) {
            state.orders = orders
        }
    },
    getters: {
        getShiftById: (state) => (id) => {
            return state.shifts.find(shift => shift.ord_shift_id == id);
        },
        getOrderById: (state) => (id) => {
            return state.orders.find(order => order.objid == id);
        },
        isLoggedIn: (state) => {
            return !!state.token;
        },
        isTokenExpired: (state) => {
            return state.tokenExpiresIn != null && Date.now() > state.tokenExpiresIn;
        },
        tokenExpiredBackwardRoute: (state) => {
            return state.tokenExpiredBackwardRoute
        },
        shifts: (state) => {
            return state.shifts
        },
        listFilter: (state) => {
            return state.listFilter
        },
        searchFormInitDone: (state) => {
            return state.searchFormInitDone
        },
        searchDateFrom: (state) => {
            return state.listFilter.from
        },
        searchDateTo: (state) => {
            return state.listFilter.to
        },
        sorderShiftBy: (state) => {
            return state.listFilter.orderBy
        },
        availableDepartments: (state) => {
            return state.availableDepartments
        },
        getShiftBreaks: (state) => {
            return state.shiftBreaks
        },
        getOrders: (state) => {
            return state.orders
        },
    }
})

export default store;