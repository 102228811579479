<template>
  <b-navbar toggleable="md" type="dark" variant="primary" sticky>
    <b-navbar-brand href="#" @click="$ebus.$emit('nav:list')">{{ $t('menu.title') }}</b-navbar-brand>

    <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>

    <b-collapse is-nav id="nav_collapse">
      <b-navbar-nav class="ml-auto">
        <template v-if="$route.path !== '/login'">
        <b-nav-item v-if="$store.getters.isLoggedIn" @click="$ebus.$emit('nav:list')">{{ $t('menu.shifts') }}</b-nav-item>
          <b-nav-item v-if="$store.getters.isLoggedIn" @click="$ebus.$emit('nav:orders')">{{ $t('menu.orders') }}</b-nav-item>
          <b-nav-item v-if="$store.getters.isLoggedIn" @click="$ebus.$emit('nav:logout')">{{ $t('menu.logout') }}</b-nav-item>
          <b-nav-item v-else @click="$ebus.$emit('nav:login')">{{ $t('menu.login') }}</b-nav-item>
          <b-nav-item @click="$ebus.$emit('nav:contact')"><img class="icon" :src="'/img/' + CUSTOMER + '/email_24px.png'"/></b-nav-item>
        </template>

        <b-nav-item-dropdown v-bind:text="$i18n.locale" right>
          <b-dropdown-item v-for="(lang, i) in ['cs', 'en']" href="#" @click="$i18n.locale = lang" :key="'Lang' + i">{{ lang.toUpperCase() }}</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
export default {
  name: 'nav-default',
  props: ['data'],
};
</script>

<style scoped lang="scss"></style>
