<template>
    <b-row v-if="shift">
        <b-col class="shift_detail" cols="12" lg="8">
            <ShiftDetailForm :shift="shift" v-on:shift-form-update="d => formData = d" editableDate clone>
                <template #buttons>
                    <b-button variant="success" @click="saveShift">{{ $t("button.save").toUpperCase() }}</b-button>
                </template>
            </ShiftDetailForm>
        </b-col>
    </b-row>
</template>


<script>
import { CONFIG } from "../config.js";
import ShiftDetailForm from "@/components/ShiftDetailForm.vue";

export default {
    props: ["parentId"],
    data: function() {
        return {
            shift: null,
            formData: {}
        };
    },
    components: { ShiftDetailForm },
    mounted: function() {
        // load data for clone
        if (this.parentId) {
            this.$protected(
                "post",
                CONFIG.api.endpoints.duplicateShift,
                r => {
                    this.shift = r.data[0];
                    this.shift.shift_state_simple = "UNAPPROVED";
                    this.$ebus.$emit("menu:update", {
                        component: "nav-clone",
                        data: { parent: this.shift.person_name }
                    });
                },
                {
                    data: { ord_shift_id_: this.parentId }
                }
            );
        }
    },
    destroyed: function() {
        this.$ebus.$emit("menu:reset");
    },
    computed: {
        shiftId: function() {
            return this.shift.ord_shift_id;
        },
        shiftVersion: function() {
            return this.shift.objversion;
        },
        shiftState: function() {
            return this.shift.shift_state_simple;
        },
        isUnapproved: function() {
            return this.shiftState == "UNAPPROVED";
        },
        hoursPaid: function() {
            return this.$moment.duration(this.formData.hour_paid || 0, "hours");
        }
    },
    methods: {
        saveShift: function() {
            this.$protected(
                "post",
                CONFIG.api.endpoints.shifts,
                r => {
                    var data = r.data[0];
                    this.$store.commit("addShift", data);
                    this.$ebus.$emit("msg:new", {
                        type: "message-success",
                        data: { text: this.$t("message.updated") },
                        persistent: true
                    });
                    this.$router.push("/detail/" + data.ord_shift_id);
                },
                {
                    data: {
                        ord_job_id: this.shift.ord_job_id,
                        shift_date: this.$moment(
                            this.formData.shift_date
                        ).format("YYYY-MM-DD"),
                        shift_time_from: this.formData.from,
                        shift_time_until: this.formData.to
                    },
                    headers: {
                        Prefer: "return=representation"
                    }
                }
            );
        }
    }
};
</script>


<style scoped lang="scss">
@import "src/scss/main";

.shift_detail {
    margin-bottom: 80px;
}

.absence-btn {
    color: $red;
    padding-left: 0px;
    padding-right: 0px;
}

.absence-btn:hover {
    color: #cf6700;
}

.approved-btn {
    color: $primary;
    padding-left: 0px;
    padding-right: 0px;
}

.excused-btn {
    color: #4a4a4a;
}

.cancel_link {
    cursor: pointer;
}
</style>
