import Vue from 'vue'
import App from './App.vue'
import VueI18n from 'vue-i18n'
import { BootstrapVue, IconsPlugin }  from 'bootstrap-vue';
import VueRouter from 'vue-router';
import Vuex from 'vuex';
import VueMoment from 'vue-moment';

import router from './router'
import store from './store'

import './api_service.js';
import './ebus.js';

import { messages as en } from './messages/en.js'
import { messages as cs } from './messages/cs.js'

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(IconsPlugin)
Vue.use(VueI18n);
Vue.use(VueRouter);
Vue.use(Vuex);
Vue.use(VueMoment);

const i18n = new VueI18n({
    locale: 'cs', // set locale
    messages: {
        en: en,
        cs: cs
    }
})

Vue.mixin({
    data: function () {
        return {
            get CUSTOMER() {
                return process.env.VUE_APP_CUSTOMER;
            }
        }
    }
})

new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
}).$mount('#app')