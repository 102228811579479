<template>
    <b-navbar type="dark" variant="primary" sticky>
        <b-navbar-brand class="long_brand" href="#" @click="$router.go(-1)">
            <img class="icon menu_back_button" :src="'/img/' + CUSTOMER + '/arrow_back_24px.png'" v-bind:alt="$t('button.back')" />
            {{ data.person_name }}
        </b-navbar-brand>

        <b-navbar-nav class="ml-auto">
            <b-nav-item @click="$ebus.$emit('nav:contact')">
                <img class="icon" :src="'/img/' + CUSTOMER + '/email_24px.png'" />
            </b-nav-item>
        </b-navbar-nav>
    </b-navbar>
</template>


<script>
export default {
    name: "nav-detail",
    props: ["data"],
    data: function() {
        return {};
    },
    methods: {
        resetForm() {
            this.$parent.$refs.view.reset();
        }
    }
};
</script>


<style scoped lang="scss">
.menu_back_button {
    cursor: pointer;
    position: relative;
    top: -2px;
}

.long_brand {
    overflow: hidden;
    white-space: nowrap; /* Don't forget this one */
    text-overflow: ellipsis;
}
</style>
