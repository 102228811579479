<template>
  <div class="orders" id="01">
    <h3>{{ $t('menu.orders') }}</h3>
    <div class="order-list">
    <b-row no-gutters align-v="center" >
      <b-col> {{ $t('orders.date') }} </b-col>
      <b-col> {{ $t('orders.department') }} </b-col>
    </b-row>

    <template v-for="order in orders">
      <Order :order="order" :key="order.objid" />
    </template>
    </div>
  </div>
</template>

<script>
import Order from '@/components/Order.vue';

export default {
  name: 'Orders',
  components: { Order },
  data: function() {
    return {};
  },
  mounted: function() {
    this.$loadOrders();
  },
  computed: {
    orders: function() {
      return this.$store.getters.getOrders;
    },
  },
};
</script>

<style lang="scss" scoped>
.file-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

h3 {
  padding-bottom: 20px;
}
.order {
  margin: 5px 0px 5px 0px;
  padding: 15px 0px 15px 0px;
  border-bottom: 1px solid #E0E0E0;
  cursor: pointer;
  font-size: 14px;
}

.order-list {
  max-width: 500px;
  margin: 0 auto;
  font-size: 14px;
  font-weight: bold;
}

</style>
