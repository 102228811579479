<template>
    <b-col cols="11">
        <b-alert show variant="danger" v-html="$t('error.api404')" />
    </b-col>
</template>


<script>
export default {
    name: "error-api-404",
    props: ['message']
};
</script>


<style scoped lang="scss">

</style>
