<template>
  <b-container>
    <b-row>
      <b-col class="text-left search_term" v-if="isTermFilterEnabled">
        <b-form-group :label="$t('list.search.term')">
          <b-input-group>
            <template v-slot:append>
              <b-input-group-text>
                <img v-show="searchTerm" @click="clearSearchTerm()" :src="'/img/' + CUSTOMER + '/close_dark_14px.png'" class="clear_button" />
              </b-input-group-text>
            </template>

            <b-form-input v-model="searchTerm"></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row v-show="isDateRangeFilterEnabled">
      <b-col class="text-left">
        <b-form-group :label="$t('list.search.date')">
          <b-form-group label-for="date-from" :label="$t('list.search.dateFrom')" class="datepicker-group">
            <Datetime v-model="dateFrom" ref="datepickerFrom" class="mr-4" :phrases="{ ok: $t('datepicker.ok'), cancel: $t('datepicker.cancel') }" :input-class="['form-control', { empty: !dateFrom }]" input-id="date-from" auto>
              <span class="vdatetime-clear" slot="after">
                <b-button v-if="dateFrom" size="sm" variant="link" :title="$t('datepicker.clear')" @click.prevent="clearSearchDateFrom">
                  <b-img :src="'/img/' + CUSTOMER + '/close_dark_14px.png'" alt="datepicker clear date button" />
                </b-button>
              </span>
            </Datetime>
          </b-form-group>

          <b-form-group label-for="date-to" :label="$t('list.search.dateTo')" class="datepicker-group">
            <Datetime v-model="dateTo" ref="datepickerTo" :phrases="{ ok: $t('datepicker.ok'), cancel: $t('datepicker.cancel') }" :input-class="['form-control', { empty: !dateTo }]" input-id="date-to" auto>
              <span class="vdatetime-clear" slot="after">
                <b-button v-if="dateTo" size="sm" variant="link" :title="$t('datepicker.clear')" @click.prevent="clearSearchDateTo">
                  <b-img :src="'/img/' + CUSTOMER + '/close_dark_14px.png'" alt="datepicker clear date button" />
                </b-button>
              </span>
            </Datetime>
          </b-form-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row v-show="isDepartmentFilterEnabled && isManagerUser">
      <b-col class="text-left">
        <b-form-group :label="$t('list.search.department')">
          <b-form-checkbox-group v-model="department" :options="departments" stacked />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col class="text-left">
        <b-form-group :label="$t('list.search.orderBy.title')">
          <b-form-radio v-model="orderBy" name="some-radios2" value="shift_date.asc">{{$t('list.search.orderBy.dateAsc')}}</b-form-radio>
          <b-form-radio v-model="orderBy" name="some-radios1" value="shift_date.desc">{{$t('list.search.orderBy.dateDesc')}}</b-form-radio>
          <b-form-radio v-model="orderBy" name="some-radios3" value="person_name">{{$t('list.search.orderBy.name')}}</b-form-radio>
        </b-form-group>
      </b-col>
    </b-row>

    

    <ButtonRow>
      <b-button variant="success" @click="applyFilter">{{ $t('button.apply') }}</b-button>
    </ButtonRow>
  </b-container>
</template>

<script>
import { CONFIG } from '../config.js';
import { Datetime } from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.min.css';
import ButtonRow from '@/components/ButtonRow.vue';

export default {
  props: [],
  data: function() {
    return {
      department: [],
      dateFrom: null,
      dateTo: null,
      searchTerm: this.term,
      orderBy: '',
    };
  },
  components: { Datetime, ButtonRow },
  mounted: function() {
    this.datepickerFromElement.addEventListener('click', () => {
      this.$ebus.$emit('msg:clear');
    });

    this.datepickerToElement.addEventListener('click', () => {
      this.$ebus.$emit('msg:clear');
    });

    this.dateFrom = this.filter.from ? this.$moment.utc(this.filter.from).format() : null;
    this.dateTo = this.filter.to ? this.$moment.utc(this.filter.to).format() : null;
    this.searchTerm = this.filter.term;
    this.department = this.filter.departments;
    this.orderBy = this.filter.orderBy;
  },
  computed: { 
    shifts() {
      return this.$store.getters.shifts;
    },
    isTermFilterEnabled() {
      return !!CONFIG.enableTermFilter;
    },
    isDepartmentFilterEnabled() {
      return !!CONFIG.enableDepartmentFilter;
    },
    isDateRangeFilterEnabled() {
      return !!CONFIG.enableDateRangeFilter;
    },
    isManagerUser() {
      return true;
    },
    departments() {
      return this.$store.getters.availableDepartments;
    },
    datepickerFromElement: function() {
      return this.$refs.datepickerFrom.$el.querySelector('#date-from');
    },
    datepickerToElement: function() {
      return this.$refs.datepickerTo.$el.querySelector('#date-to');
    },
    filter: function() {
      return this.$store.getters.listFilter;
    },
  },
  methods: {
    clearSearchDateFrom: function() {
      this.dateFrom = null;
    },
    clearSearchDateTo: function() {
      this.dateTo = null;
    },
    clearDepartment: function() {
      this.department = [];
    },
    clearSearchTerm: function() {
      this.searchTerm = null;
    },
    deployFromDatepicker: function() {
      this.datepickerFromElement.click();
    },
    deployToDatepicker: function() {
      this.datepickerToElement.click();
    },
    applyFilter: function() {
      this.$emit('list-filter-apply', {
        from: this.dateFrom,
        to: this.dateTo,
        departments: this.department || [],
        term: this.searchTerm,
        orderBy: this.orderBy,
      });
    },
    clearFilter: function() {
      this.clearSearchDateFrom();
      this.clearSearchDateTo();
      this.clearDepartment();
      this.clearSearchTerm();
      this.$emit('list-filter-clear');
    },
  },
};
</script>

<style lang="scss">
@import 'src/scss/variables';

.vdatetime {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.vdatetime-overlay {
  z-index: $z-index-datepicker-overlay !important;
}

.vdatetime-popup {
  z-index: $z-index-datepicker-popup !important;
}

.vdatetime-input {
  width: 125px;
  text-align: center;
  cursor: pointer;
  background-color: white;
}

.vdatetime-icon {
  margin-right: 10px;
  line-height: 0;
  cursor: pointer;
}

.vdatetime-clear {
  margin-left: 10px;
  line-height: 0;

  button {
    border: none;
    background-color: transparent;
    padding: 0 !important;
    margin: 0 !important;
    line-height: 0;
  }
}

.datepicker-group {
  display: inline-block;
  margin: 0 !important;

  input {
    text-align: center;
  }
}

.col-form-label {
  font-weight: 700;
  margin-bottom: 15px;
}
</style>

<style scoped lang="scss">
@import 'src/scss/variables';

.filter-overlay {
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: $z-index-list-filter-overlay;
  top: 0;
  left: 0;
  background-color: #fff;

  .form-group {
    margin-top: 30px;
    margin-bottom: 0px;
  }
}

.clear_button {
  cursor: pointer;
}

.search_term {
  .input-group-text {
    background-color: #fff;
  }

  input {
    border-right: none;
  }
}
</style>
