<template>
  <fragment>
    <b-row class="detail_section">
      <b-col cols="2">
        <img class="icon" :src="'/img/' + CUSTOMER + '/work_24px.png'" />
      </b-col>
      <b-col cols="10" class="detail_content_section">
        <h4>
          {{ $t('shift.detail') }}&nbsp;&nbsp;
          <a href="#" v-if="isShiftCloningEnabled && !clone" variant="info" @click.stop="duplicateShift">
            <img id="duplicate_icon" class="icon" :src="'/img/' + CUSTOMER + '/copy_24px.png'" />
          </a>
        </h4>
        <div v-if="displayStars">
          <template v-if="isUnapproved">
            <star-rating :read-only="false" :show-rating="false" v-model="formData.stars" :star-size="40"></star-rating>
          </template>
          <template v-else>
            <star-rating v-bind:read-only="true" v-bind:show-rating="false" v-model="formData.stars" v-bind:star-size="40"></star-rating>
          </template>
        </div>

        <b-row class="time_buttons" no-gutters align-v="center" v-if="shiftButtons && shift.shift_state_simple == 'UNAPPROVED'">
          <b-col>
            <template v-for="(b, i) in shiftButtons">
              <b-button
                variant="outline-success"
                :key="i"
                size="sm"
                @click="
                  formData.from = b.time_from;
                  formData.to = b.time_until;
                "
                >{{ b.description }}</b-button
              >&nbsp;
            </template>
          </b-col>
        </b-row>

        <b-form class="time_edit_form">
          <b-row no-gutters align-v="center">
            <b-col class="shift-date col-12 col-sm-12 col-md-2 ">
              <template v-if="editableDate">
                <Datetime
                  v-model="formData.shift_date"
                  ref="datepickerFrom"
                  class="mr-4"
                  :phrases="{ ok: $t('datepicker.ok'), cancel: $t('datepicker.cancel') }"
                  :input-class="['form-control', { empty: !formData.shift_date }]"
                  input-id="shift-date"
                  auto
                />
              </template>
              <template v-else>{{ [shift.shift_date, 'YYYY-MM-DD'] | moment('D.M.YYYY') }}</template>
            </b-col>
            <b-col class="from-label-css col-6 col-sm-6 col-md-1">
              <label for="shift-from">{{ $t('shift.from') }}</label>
            </b-col>
            <b-col class="from-value-css col-6 col-sm-6 col-md-3">
              <strong v-if="shift.shift_state_simple != 'UNAPPROVED'">{{ parseShiftDateTime(formData.from) | moment('HH:mm') }}</strong>
              <b-form-select v-else v-model="formData.from" id="shift-from">
                <option v-for="(n, i) in timeOptions" :key="i" :value="n | moment('HH:mm:ss')">{{ n | moment('HH:mm') }}</option>
              </b-form-select>
            </b-col>

            <div class="w-100 d-md-none"></div>

            <b-col class="to-label-css col-6 col-sm-6 col-md-1">
              <label for="shift-to">{{ $t('shift.to') }}</label>
            </b-col>
            <b-col class="to-value-css col-6 col-sm-6 col-md-3">
              <strong v-if="shift.shift_state_simple != 'UNAPPROVED'">{{ parseShiftDateTime(formData.to) | moment('HH:mm') }}</strong>
              <b-form-select v-else v-model="formData.to" id="shift-to">
                <option v-for="(n, i) in timeOptions" :key="i" :value="n | moment('HH:mm:ss')">{{ n | moment('HH:mm') }}</option>
              </b-form-select>
            </b-col>
          </b-row>

          <b-modal ref="modal-add-break" id="modal-add-break" title="Přestávka" hide-footer>
            <b-row>
              <b-col class="col-6">
                <label for="break-from" class="from_label">{{ $t('shift.from') }}</label>

                <strong v-if="shift.shift_state_simple != 'UNAPPROVED'">{{ parseShiftDateTime(formData.last_break_from) | moment('HH:mm') }}</strong>
                <b-form-select v-else v-model="formData.last_break_from" id="break-from">
                  <option v-for="(n, i) in StarttimeOptions" :key="i" :value="n | moment('HH:mm:ss')">{{ n | moment('HH:mm') }}</option>
                </b-form-select>
              </b-col>

              <b-col class="col-6">
                <label for="break-to" class="to_label">{{ $t('shift.to') }}</label>

                <strong v-if="shift.shift_state_simple != 'UNAPPROVED'">{{ parseShiftDateTime(formData.last_break_to) | moment('HH:mm') }}</strong>
                <b-form-select v-else v-model="formData.last_break_to" id="break-to">
                  <option v-for="(n, i) in StarttimeOptions" :key="i" :value="n | moment('HH:mm:ss')">{{ n | moment('HH:mm') }}</option>
                </b-form-select>
              </b-col>
            </b-row>
            <b-row class="pt-3">
              <b-col class="col-6">
                <b-button class="" block @click="hideModal('modal-add-break')">STORNO</b-button>
              </b-col>
              <b-col class="col-6">
                <b-button class="OK-button" block @click="addBreak()">OK</b-button>
              </b-col>
            </b-row>
          </b-modal>

          <div v-if="!oneBreakMode">
            <b-row>
              {{ $t('shift.breaks') }}
            </b-row>
            <b-modal ref="modal-delete-break" id="modal-delete-break" hide-footer>
              <b-row>
                <b-col class="col-12 py-3">Smazat prestavku? </b-col>
              </b-row>
              <b-row class="pt-3">
                <b-col class="col-6">
                  <b-button class="" block @click="hideModal('modal-delete-break')">STORNO</b-button>
                </b-col>
                <b-col class="col-6">
                  <b-button class="OK-button" block @click="deleteBreak(deleteBreakNumber)">OK</b-button>
                </b-col>
              </b-row>
            </b-modal>
            <template v-for="shiftB in shiftBreaks">
              <b-row :key="shiftB.break_no" no-gutters align-v="center" class="break">
                <b-row no-gutters align-v="center" class="break">
                  {{ parseShiftDateTime(shiftB.break_time_from) | moment('HH:mm') }} - {{ parseShiftDateTime(shiftB.break_time_until) | moment('HH:mm') }}
                  <b-button v-b-modal.modal-delete-break class="delete-btn" @click="openModal(shiftB.break_no)"><b-icon-x></b-icon-x></b-button>
                </b-row>
              </b-row>
            </template>
            <b-button v-if="shift.shift_state_simple == 'UNAPPROVED'" @click="showModal('modal-add-break')"><b-icon-plus></b-icon-plus></b-button>
          </div>

          <b-row no-gutters v-if="oneBreakMode">
            <b-col class="break-length-label col-6">
              {{ $t('shift.break') }}
            </b-col>
            <b-col class="col-6 padding-left">
              <strong>{{ parseShiftDateTime(formData.oneBreakTime) | moment('HH:mm') }}</strong>

              <!--<strong v-if="shift.shift_state_simple != 'UNAPPROVED'">{{ parseShiftDateTime(formData.oneBreakTime) | moment('HH:mm') }}</strong>
              <b-form-select class="break-length-select" v-else v-model="formData.oneBreakTime">
                <option v-for="(n, i) in singleBreakTimeOptions" :key="i" :value="n | moment('HH:mm:ss')">{{ cutSeconds(n)}}</option>
              </b-form-select>-->
            </b-col>
          </b-row>

          <b-row no-gutters align-v="center" class="worked_">
            <b-col class="break-length-label label col-6 col-sm-7 col">{{ $t('shift.worked') }}</b-col>
            <b-col class="col-6 col-sm-5 padding-left">
              <strong>{{hoursPaid}}</strong>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>

    <b-row class="detail_section photo" v-if="photo">
      <b-col cols="2">
        <img class="icon" :src="'/img/' + CUSTOMER + '/person_24px.png'" />
      </b-col>
      <b-col cols="10" class="text-left">
        <b-img :src="'data:image/png;base64,' + photo" />
      </b-col>
    </b-row>

    <b-row class="detail_section" v-if="person && person.email">
      <b-col cols="2">
        <img class="icon" :src="'/img/' + CUSTOMER + '/email_dark_24px.png'" />
      </b-col>
      <b-col cols="10" class="detail_content_section">
        <a class="text-break" :href="'mailto:' + person.email">{{ person.email }}</a>
      </b-col>
    </b-row>

    <b-row class="detail_section" v-if="person && (person.phone || person.mobile)">
      <b-col cols="2">
        <img class="icon" :src="'/img/' + CUSTOMER + '/call_24px.png'" />
      </b-col>
      <b-col cols="10" class="detail_content_section">
        <a v-if="person.mobile" :href="'tel:' + person.mobile">{{ person.mobile }}</a>
        <span v-if="person.mobile && person.phone">,</span>
        <a v-if="person.phone" :href="'tel:' + person.phone">{{ person.phone }}</a>
      </b-col>
    </b-row>

    <ButtonRow>
      <slot name="buttons"></slot>
    </ButtonRow>
  </fragment>
</template>

<script>
import { CONFIG } from '../config.js';
import ButtonRow from '@/components/ButtonRow.vue';
import { Fragment } from 'vue-fragment';
import { Datetime } from 'vue-datetime';
import StarRating from 'vue-star-rating';
import 'vue-datetime/dist/vue-datetime.min.css';

export default {
  props: {
    shift: { type: Object, required: true },
    dateFrom: { type: String, default: null },
    dateTo: { type: String, default: null },
    editableDate: { type: Boolean, default: false },
    clone: { type: Boolean, default: false },
  },
  data: function() {
    return {
      timeOptionsStep: 15, // in minutes
      person: {},
      photo: null,
      shiftButtons: [],
      formData: {
        stars: this.shift.stars,
        from: this.dateFrom,
        to: this.dateTo,
        break: this.breakLengthCounted,
        shift_hour: this.shift.shift_hour,
        hour_paid: this.shift.hour_paid,
        shift_date: null,
        last_break_to: null,
        last_break_from: null,
        oneBreakTime: null,
      },
      deleteBreakNumber: 0,
    };
  },
  components: { ButtonRow, Fragment, Datetime, StarRating },
  mounted: function() {
    if (!this.formData.from) {
      this.formData.from = this.shift.shift_time_from || '00:00:00';
    }

    if (!this.formData.to) {
      this.formData.to = this.shift.shift_time_until || '00:00:00';
    }

    if (!this.formData.shift_date) {
      this.formData.shift_date = this.$moment.utc(this.shift.shift_date).format();
    }

    if (this.shift.person_id) {
      this.$ebus.$emit('ajax:start');
      this.$protected(
        'get',
        CONFIG.api.endpoints.person,
        r => {
          this.person = r.data[0];
        },
        {
          params: {
            person_id: 'eq.' + this.shift.person_id,
          },
        },
      );
    }
    this.$protected(
      'get',
      CONFIG.api.endpoints.shiftButtons,
      r => {
        this.shiftButtons = r.data;
      },
      {
        params: {
          department_id: 'eq.' + this.shift.department_id,
          work_type_id: 'eq.' + this.shift.work_type,
        },
      },
    );
    this.addWatchers();

    if (this.oneBreakMode) {
      this.updateWorkedAndPaidOneBreak()
    }

    if (!this.oneBreakMode) {
      this.$loadShiftBreak(this.shift.ord_shift_id);
    } 
    /*else if (this.shift.shift_state_simple != 'UNAPPROVED') {
      
      let HourMinutesTo = String(this.formData.to).split(/[.:]/);
      let HourMinutesFROM = String(this.formData.from).split(/[.:]/);

      let finalHour = parseInt(HourMinutesTo[0], 10) - parseInt(HourMinutesFROM[0], 10);
      let finalMinutes = (HourMinutesTo[1] ? parseInt(HourMinutesTo[1], 10) : 0) - (HourMinutesFROM[1] ? parseInt(HourMinutesFROM[1], 10) : 0);

      let sec_num = parseInt(this.shift.hour_paid * 3600, 10);
      let hours = Math.floor(sec_num / 3600);
      let minutes = Math.floor((sec_num - hours * 3600) / 60);

      if (finalMinutes < 0) {
        finalHour = finalHour - 1;
        finalMinutes = 60 + finalMinutes;
      }

      finalHour = finalHour - hours;
      finalMinutes = finalMinutes - minutes;
      if (finalMinutes < 0) {
        finalHour = finalHour - 1;
        finalMinutes = 60 + finalMinutes;
      }

      if (finalHour < 9) {
        finalHour = '0' + String(finalHour);
      }
      if (finalMinutes < 9) {
        finalMinutes = '0' + String(finalMinutes);
      }

      if (hours < 10) {
        hours = '0' + hours;
      }
      if (minutes < 10) {
        minutes = '0' + minutes;
      }

      this.formData.oneBreakTime = String(finalHour) + ':' + String(finalMinutes) + ':00';
      
    }*/
  },
  watch: {
    shiftBreaks: function() {
      this.updateWorkedAndPaid();
    },
    person: function(value) {
      if (this.isEmpty(value)) {
        return;
      }

      var self = this;

      // photo
      this.$protected(
        'post',
        CONFIG.api.endpoints.photo,
        function(r) {
          // empty string is needed for watcher triggering, null doesn't trigger it
          self.photo = r.data ? self.hexToBase64(r.data) : '';
        },
        {
          data: {
            photo_: this.person.photo,
          },
        },
      );
    },
    photo: function() {
      this.$ebus.$emit('ajax:finished');
    },
    'formData.shift_date': function() {
      this.$emit('shift-form-update', this.formData);
    },
  },
  computed: {
    shiftId: function() {
      return this.shift.ord_shift_id;
    },
    shiftVersion: function() {
      return this.shift.objversion;
    },
    shiftState: function() {
      return this.shift.shift_state_simple;
    },
    timeOptions: function() {
      var dt = this.parseShiftDateTime('00:00:00');
      var day = dt.date();

      var options = [];
      var stop = false;
      while (!stop) {
        options.push(dt.clone());

        dt.add(this.timeOptionsStep, 'minutes');
        if (dt.date() != day) {
          stop = true;
        }
      }

      return options;
    },
    singleBreakTimeOptions: function() {
      return [
        '00:00:00',
        '00:15:00',
        '00:30:00',
        '00:45:00',
        '01:00:00',
        '01:15:00',
        '01:30:00',
        '01:45:00',
        '02:00:00',
      ]
    },

    StarttimeOptions: function() {
      var startTime = this.parseShiftDateTime(this.formData.from);
      var endTime = this.parseShiftDateTime(this.formData.to);

      /*  let startHour = String(startTime._i)
        .split(' ')[1]
        .split(':')[0];
      let endHour = String(endTime._i)
        .split(' ')[1]
        .split(':')[0];

      let startMinutes = String(startTime._i)
        .split(' ')[1]
        .split(':')[1];
      let endMinutes = String(endTime._i)
        .split(' ')[1]
        .split(':')[1];

      
      let numberHour = 0;
      if (startHour > endHour) {
        numberHour = 24 - (startHour - endHour);
      } else if (startHour < endHour) {
        numberHour = endHour - startHour;
      }

      let numberMinutes = endMinutes - startMinutes;
      if (numberMinutes > 0 ) {
        numberMinutes = 24 - (startHour - endHour);
      } else if (numberMinutes < 0) {
        numberHour = numberHour * 60 + numberMinutes;
      } */
      let milliseconds = 0;

      if (startTime > endTime) {
        milliseconds = 24 * 60 * 60 * 1000 - (new Date(startTime) - new Date(endTime));
      } else if (startTime < endTime) {
        milliseconds = new Date(endTime) - new Date(startTime);
      }

      let minutes = milliseconds / 1000 / 60;
      var options = [];

      while (minutes - this.timeOptionsStep >= -this.timeOptionsStep) {
        minutes = minutes - this.timeOptionsStep;
        options.push(startTime.clone());
        startTime.add(this.timeOptionsStep, 'minutes');
      }

      return options;
    },
    hoursPaid: function() {
      const duration = this.$moment.duration(this.formData.hour_paid || 0, 'hours');
      return this.$moment.utc(duration.as('milliseconds')).format('HH:mm')
    },
    isUnapproved: function() {
      return this.shiftState == 'UNAPPROVED';
    },
    oneBreakMode: function() {
      return CONFIG.OneBreakMode;
    },
    isShiftCloningEnabled: function() {
      return CONFIG.enableShiftClone;
    },
    shiftBreaks: function() {
      return this.$store.getters.getShiftBreaks;
    },
    displayStars() {
      const displayStars = CONFIG.displayStars
      if (!displayStars || displayStars == 'false') return false
      return true
    }
  },
  methods: {
    addWatchers() {
      this.$watch('formData.from', function() {
        if (this.oneBreakMode) {
          this.updateWorkedAndPaidOneBreak();
        } else {
          this.updateWorkedAndPaid();
        }
        this.$emit('shift-form-update', this.formData);
      });

      this.$watch('formData.to', function() {
        if (this.oneBreakMode) {
          this.updateWorkedAndPaidOneBreak();
        } else {
          this.updateWorkedAndPaid();
        }

        this.$emit('shift-form-update', this.formData);
      });

      this.$watch('formData.oneBreakTime', function() {
        //this.updateWorkedAndPaidOneBreak();
        this.$emit('shift-form-update', this.formData);
      });
    },

    cutSeconds(time) {
      return time.substr(0, 5)
    },

    parseShiftDateTime: function(time) {
      return this.$moment(this.shift.shift_date + ' ' + time, 'YYYY-MM-DD HH:mm:ss');
    },
    isEmpty: function(o) {
      return o == null || !Object.keys(o).length;
    },
    hexToBase64: function(str) {
      if (str == null) {
        return null;
      }

      // string begins with \x (hex format prefix)
      var hex = str
        .trim()
        .substring(2)
        .replace(/\r|\n/g, '')
        .replace(/([\da-fA-F]{2}) ?/g, '0x$1 ')
        .replace(/ +$/, '')
        .split(' ');

      return Buffer.from(hex, 'hex').toString('base64');
    },
    updateWorkedAndPaid: function() {
      this.$protected(
        'post',
        CONFIG.api.endpoints.calcHourWithMoreBreak,

        response => {
          this.formData.hour_paid = response.data.hour_paid;
        },
        {
          data: {
            ord_shift_id_: this.shift.ord_shift_id,
            shift_time_from_: this.formData.from,
            shift_time_until_: this.formData.to,
            breaks_: this.shiftBreaks.map(({ break_time_from, break_time_until }) => ({ break_time_from, break_time_until })),
          },
        },
      );
    },
    duplicateShift: function() {
      this.$emit('duplicate');
    },
    addBreak: function() {
      let lastBreakNumber = 0;

      if (this.shiftBreaks.length > 0) {
        lastBreakNumber = this.shiftBreaks[this.shiftBreaks.length - 1].break_no;
      }

      this.$protected(
        'post',
        CONFIG.api.endpoints.shiftBreaks,
        () => {
          this.formData.last_break_from = null;
          this.formData.last_break_to = null;
          this.$refs['modal-add-break'].hide();
          this.$loadShiftBreak(this.shift.ord_shift_id);
        },
        {
          data: {
            owner_id: this.shift.owner_id,
            order_no: this.shift.order_no,
            line_no: this.shift.line_no,
            job_no: String(this.shift.ord_shift_id).split('-')[2],
            shift_no: String(this.shift.ord_shift_id).split('-')[3],
            break_time_from: this.formData.last_break_from,
            break_time_until: this.formData.last_break_to,
            break_no: lastBreakNumber + 1,
          },
        },
      );
    },
    deleteBreak: function(break_no) {
      this.$protected(
        'DELETE',
        CONFIG.api.endpoints.shiftBreaks + '?ord_shift_id=eq.' + this.shift.ord_shift_id + '&break_no=eq.' + break_no,
        () => {
          this.$loadShiftBreak(this.shift.ord_shift_id);
          this.deleteBreakNumber = null;
          this.$refs['modal-delete-break'].hide();
        },
        null,
      );
    },
    openModal: function(break_no) {
      this.deleteBreakNumber = break_no;
    },
    breakLengthCounted: function() {
      let HourMinutes = String(this.formData.oneBreakTime).split(/[.:]/);
      let hours = parseInt(HourMinutes[0], 10);
      let minutes = HourMinutes[1] ? parseInt(HourMinutes[1], 10) : 0;
      return hours + minutes / 60;
    },
    updateWorkedAndPaidOneBreak: function() {
      this.$protected(
        'POST',
        CONFIG.api.endpoints.calcHourWithOneBreak,
        response => {
          this.formData.hour_paid = response.data.hour_paid;
          this.shift.hours_paid = response.data.hour_paid;
          this.formData.oneBreakTime = response.data.break_duration;
        },
        {
          data: {
            ord_shift_id_: this.shift.ord_shift_id,
            shift_time_from_: this.formData.from,
            shift_time_until_: this.formData.to,
            break_duration_: this.breakLengthCounted(),
          },
        },
      );
    },
    hideModal: function(name_modal) {
      if (name_modal == 'modal-delete-break') {
        this.$refs['modal-delete-break'].hide();
      } else if (name_modal == 'modal-add-break') {
        this.formData.last_break_from = null;
        this.formData.last_break_to = null;
        this.$refs['modal-add-break'].hide();
      }
    },
    showModal: function(name_modal) {
      if (name_modal == 'modal-add-break') {
        //console.log(this.formData.from);
        // tady by to chtelo urcite dat o ten time options step dopredu a dozadu
        this.formData.last_break_from = this.formData.from;
        this.formData.last_break_to = this.formData.from;
        this.$refs['modal-add-break'].show();
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import 'src/scss/main';

h4 {
  margin-top: 6px;
}

#duplicate_icon {
  width: 20px;
  height: 20px;
}

.detail_section {
  padding-top: 30px;
}

.detail_content_section {
  .row {
    margin: 10px 0;
  }

  .time_buttons {
    margin-bottom: 20px;
  }
}

.time_edit_form label,
.time_edit_form select {
  display: inline;
}

#shift-from,
#shift-to,
#break-length {
  display: inline-block;
  width: 100px;
}

.worked_month {
  color: $gray-500;
}

.worked_days {
  font-size: 1.2rem;
}

.detail_section.photo img {
  max-height: 200px;
  max-width: 200px;
}

@include media-breakpoint-down(md) {
  .detail_section.photo img {
    max-height: 90%;
    max-width: 90%;
  }
}

@include media-breakpoint-down(xs) {
  .state_description {
    display: none;
  }

  .detail_section {
    padding-top: 20px;
  }
}

.from_label,
.to_label {
  text-align: right;
}

@include media-breakpoint-down(xxxs) {
  .time_edit_form {
    .shift_date {
      @include make-col(12);
    }

    .from_label,
    .to_label {
      @include make-col-offset(0);
      text-align: left;
    }
  }

  .worked .label,
  .worked .label + *,
  .break .label,
  .break .label + * {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
}

@include media-breakpoint-down(xxxxs) {
  .break .label,
  .worked .label {
    @include make-col(12);
  }
}

.delete-btn {
  margin: 0px 5px 0px 15px;
  padding: 0px 5px 0px 5px;
}
.OK-button {
  color: #4a4a4a;
  background-color: #8dc540;
}

/*.break-css {
  min-width: 95px;
} 
.break-length-select {
  
}*/

.break-length-select {
  max-width: 100px !important;
}

@include media-breakpoint-down(sm) {
  .from-label-css,
  .from-value-css,
  .to-label-css,
  .to-value-css {
    margin-top: 10px;
  }
}

@include media-breakpoint-up(md) {
  .from-label-css,
  .to-label-css {
    max-width: 30px;
  }
  .to-label-css {
    margin-left: 10px;
  }

  .from-value-css,
  .to-value-css {
    max-width: 100px !important;
  }

  .break-length-label {
    max-width: 125px;
  }
  .shift-date {
    max-width: 95px;
    min-width: 95px;
  }
  .padding-left {
    padding-left: 15px !important;
  }
}
</style>
