<template>
  <div id="app">
    <component :is="menu.component" :data="menu.data" />

    <b-container id="content">
      <b-row align-h="center" if="messages">
        <component v-for="(m, i) in messages" :is="m.type" :key="m.type + '-' + i" v-bind:message="m.data" v-on:modal:close="dropModalMessage" />
      </b-row>

      <router-view ref="view" />
    </b-container>

    <ajax-loader :display="runningAjaxRequests" />
  </div>
</template>

<script>
import NavDefault from '@/components/navigation/Default.vue';
import NavDetail from '@/components/navigation/Detail.vue';
import NavContact from '@/components/navigation/Contact.vue';
import NavClone from '@/components/navigation/Clone.vue';
import ErrorApi400 from '@/components/messages/errors/Api400.vue';
import ErrorApi401 from '@/components/messages/errors/Api401.vue';
import ErrorApi404 from '@/components/messages/errors/Api404.vue';
import ErrorApi403 from '@/components/messages/errors/Api403.vue';
import ErrorDefault from '@/components/messages/errors/Default.vue';
import ErrorModal from '@/components/messages/errors/Modal.vue';
import MessageSuccess from '@/components/messages/Success.vue';
import SuccessModal from '@/components/messages/SuccessModal.vue';
import AjaxLoader from '@/components/AjaxLoader.vue';

import { CONFIG } from './config.js';

import '@babel/polyfill';

export default {
  data: function() {
    return {
      messages: [],
      menu: { component: 'nav-default' },
      runningAjaxRequests: 0,
    };
  },
  components: {
    AjaxLoader,
    NavDefault,
    NavDetail,
    NavContact,
    NavClone,
    ErrorApi400,
    ErrorApi401,
    ErrorApi403,
    ErrorApi404,
    ErrorDefault,
    ErrorModal,
    MessageSuccess,
    SuccessModal,
  },
  watch: {
    runningAjaxRequests: function(value) {
      // disable scrolling if loader is displayed
      document.body.style.overflow = value ? 'hidden' : 'visible';
    },
    $route: function() {
      this.clearMessages();
    },
  },
  mounted() {
    this.$ebus.$on('nav:login', () => this.login());
    this.$ebus.$on('nav:logout', () => this.logout());
    this.$ebus.$on('nav:contact', () => this.contact());
    this.$ebus.$on('modal:close', () => this.dropModalMessage());
    this.$ebus.$on('ajax:start', () => this.addRunningAjax());
    this.$ebus.$on('ajax:finished', () => this.finishRunningAjax());
    this.$ebus.$on('menu:update', event => this.updateMenu(event));
    this.$ebus.$on('menu:reset', () => this.resetMenu());
    this.$ebus.$on('msg:new', event => this.addMessage(event));
    this.$ebus.$on('msg:clear', () => this.clearMessages());
    this.$ebus.$on('msg:drop', event => this.dropMessage(event));
    this.$ebus.$on('nav:orders', () => this.orders());
    this.$ebus.$on('nav:list', () => this.list());

    // before a request is made show the progress and clear messages
    this.$api.interceptors.request.use(
      config => {
        this.$ebus.$emit('ajax:start');
        return config;
      },
      error => {
        this.$ebus.$emit('ajax:finished');
        return Promise.reject(error);
      },
    );

    // before a response is returned hide progress
    this.$api.interceptors.response.use(
      response => {
        this.$ebus.$emit('ajax:finished');
        return response;
      },
      error => {
        this.$ebus.$emit('ajax:finished');
        return Promise.reject(error);
      },
    );

    if (localStorage.token) {
      this.$store.commit('setToken', localStorage.token);
      this.$store.commit('setTokenExpiresIn', localStorage.expires);

      if (this.$route.fullPath == '/login' && !this.$store.getters.isTokenExpired) {
        this.$router.push({ path: '/list' });
      } else if (this.$router.currentRoute.meta != null && this.$router.currentRoute.meta.requiresAuth == true && !this.$store.getters.isTokenExpired) {
        var path = this.$store.getters.tokenExpiredBackwardRoute || CONFIG.afterLoginRoute;
        this.$router.push({ path: path });
      }
    }
  },
  methods: {
    login: function() {
      this.$store.commit('setToken', null);
      this.$store.commit('setTokenExpiresIn', null);
      this.$router.push({ path: '/login' });
    },
    list: function() {
      this.$router.push({ path: '/list' });
    },
    contact: function() {
      this.$router.push({ path: '/contact' });
    },
    logout: function() {
      this.$store.commit('setToken', null);
      this.$store.commit('setTokenExpiresIn', null);
      this.$router.push({ path: '/login' });
    },
    addRunningAjax: function() {
      this.runningAjaxRequests++;
    },
    finishRunningAjax: function() {
      if (this.runningAjaxRequests > 0) {
        this.runningAjaxRequests--;
      }
    },
    updateMenu: function(v) {
      this.menu = v;
    },
    resetMenu: function() {
      this.menu = { component: 'nav-default' };
    },
    clearMessages: function() {
      // drop all messages except persistent
      this.dropMessage(m => m.persistent !== true);
      // cancel persistency of persistent messages (they are persistent only in first cleaning)
      this.messages.forEach(m => (m.persistent = false));
    },
    addMessage: function(message) {
      this.messages.push(message);
    },
    dropMessage: function(filter) {
      this.messages = this.messages.filter(function(m) {
        return !filter(m);
      });
    },
    dropModalMessage: function() {
      this.dropMessage(function(m) {
        return m.type == 'error-modal';
      });
    },
    orders: function() {
      this.$router.push({ path: '/orders' });
    },
  },
};
</script>

<style lang="scss">
@import 'src/scss/main';

#content {
  padding-top: $content-top-padding;
}

@include media-breakpoint-down(xs) {
  .navbar {
    font-size: 80%;
  }
}

@include media-breakpoint-down(xxxs) {
  .navbar-brand {
    white-space: normal;
  }
}
</style>
