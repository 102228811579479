<template>
  <div class="form-detail">
    <b-row>
      <b-col> {{ $t('orders.number') }}: </b-col>
      <b-col>
        {{ order.order_no }}
      </b-col>
    </b-row>
    <b-row>
      <b-col> {{ $t('orders.date') }}: </b-col>
      <b-col>
        {{ parseOrderDateTime(order.created_date) }}
      </b-col>
    </b-row>
    <b-row>
      <b-col> {{ $t('orders.department') }}: </b-col>
      <b-col>
        {{ order.department_id }}
      </b-col>
    </b-row>

    <h3>{{ $t('orders.photos') }}</h3>
    <b-row v-for="file in files" v-bind:key="file.doc_name">
      <b-img class="img-previe" v-if="file.photo" :src="`data:image/jpeg;base64,${file.photo}`" />
      <b-button class="del-photo" v-on:click="deleteDocument(file.bin_object_id)">{{ $t('orders.del_file') }}</b-button>
    </b-row>
    <b-row>
      <b-button class="button-add-photo">
        <label for="file">{{ $t('orders.add_file') }} </label>
        <input type="file" id="file" class="form-control file-input" v-on:change="createFile($event)"   />
      </b-button>
    </b-row>
  </div>
</template>

<script>
import file from '../services/file';
import { CONFIG } from '../config.js';

export default {
  name: 'OrderDetail',
  props: ['id'],

  data() {
    return {
      order: this.$store.getters.getOrderById(this.$route.params.id),
      files: [],
    };
  },
  mounted() {
    this.getAllProfileDocuments();
    //
  },
  methods: {
    deleteDocument: function(id_document) {
      this.$protected(
        'DELETE',
        CONFIG.api.endpoints.getDocToOrder + '?order_no=eq.' + this.order.order_no + '&bin_object_id=eq.' + id_document,
        () => {
          this.getAllProfileDocuments();
        },
        {
          data: {},
        },
      );
    },
    parseOrderDateTime: function(time) {
      return this.$moment.utc(time).format('D.M.YYYY');
    },
    hexToBase64: function(str) {
      if (str == null) {
        return null;
      }

      // string begins with \x (hex format prefix)
      var hex = str
        .trim()
        .substring(2)
        .replace(/\r|\n/g, '')
        .replace(/([\da-fA-F]{2}) ?/g, '0x$1 ')
        .replace(/ +$/, '')
        .split(' ');

      return btoa(
        new Uint8Array(hex).reduce(function(data, byte) {
          return data + String.fromCharCode(byte);
        }, ''),
      );
    },
    async getBinDocumen(par_object) {
      await this.$protected(
        'POST',
        CONFIG.api.endpoints.getDocToOrderBin,

        response => {
          par_object['photo'] = this.hexToBase64(response.data);
          this.files.push(par_object);
        },
        {
          data: {
            bin_object_id_: par_object.bin_object_id,
          },
        },
      );
    },
    async getAllProfileDocuments() {
      await this.$protected(
        'GET',
        CONFIG.api.endpoints.getDocToOrder + '?order_no=eq.' + this.order.order_no,

        response => {
          this.files = [];
          //console.log(this.files);
          for (let i = 0; i < response.data.length; i++) {
            this.getBinDocumen(response.data[i]);
          }
        },
        {
          data: {},
        },
      );
    },
    async createFile(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      let base64 = await file.getBase64(files[0]);      
      let fileName = files[0].name;
      try {
        this.$protected(
          'post',
          CONFIG.api.endpoints.upload_doc,

          () => {
            this.getAllProfileDocuments();
            e.target.value = ''
          },
          {
            data: {
              order_no_: this.order.order_no,
              doc_name_: fileName,
              document_base64_: base64.split(',')[1],
            },
          },
        );
      } catch (error) {
        throw(error)
      }
    },
  }
};
</script>

<style>
.file-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.img-previe {
  max-height: 200px;
  max-width: 200px;
  padding: 10px;
}

.form-detail {
  padding-top: 30px;
}

h3 {
  padding-top: 20px;
}

@media (min-width: 576px) {
  .form-detail {
    margin: 0 auto;
    max-width: 70%;
  }
}

.button-add-photo {
  margin: 0 auto;
}
.del-photo {
  max-height: 40px;
}
</style>
