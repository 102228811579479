<template>
    <b-col cols="11" v-if="message">
        <b-alert show variant="danger" v-html="$t('error.api403')" />
    </b-col>
</template>


<script>
export default {
    name: "error-api-403",
    props: ['message']
};
</script>


<style scoped lang="scss">

</style>
