export const messages = {
  menu: {
    login: 'Přihlásit se',
    list: 'Seznam směn',
    about: 'O aplikaci',
    logout: 'Odhlásit se',
    title: 'Evidence směn',
    clone: "Kopírování směny '{0}'",
    orders : 'Objednávky',
    shifts:'Směny',
  },
  button: {
    approve: 'SCHVÁLIT',
    reject: 'Zamítnout',
    login: 'Přihlásit se',
    ok: 'OK',
    absence: 'ABSENCE',
    back: 'Zpět',
    save: 'Uložit',
    cancel: 'Zrušit',
    excuse: 'OMLUVIT',
    send: 'ODESLAT',
    clear: 'VYČISTIT',
    apply: 'APLIKOVAT',
    duplicate: 'KLONOVAT',
    close: 'Zavřít',
  },
  error: {
    shiftNotExists: 'Směna {0} neexistuje',
    notFound: 'Nenalezeno',
    api404: 'Zdroj nenalezen',
    api401: 'Platnost tokenu vypršela. Přihlašte se znovu prosím.',
    api403: 'Přístup zamítnut. Požadovanou akci nelze provést.',
    timeFromAfterTimeTo: 'Čas začátku směny musí být před koncem směny.',
    timeToBeforeTimeFrom: 'Čas konce směny musí být po začátku směny.',
    login: 'Nepodařilo se přihlásit. Chybné jméno nebo heslo.',
    shiftState: 'Směna nemůže být uložena znovu se stejným statusem.',
    system: 'Systémová chyba',
    version: 'Změny se nepodařilo uložit, směna byla upravena na pozadí.',
    deleted: 'Změny se nepodařilo uložit, směna byla smazána na pozadí.',
    closedPeriod: "Záznam nelze upravit. Období '{0}' bylo uzavřeno.",
  },
  message: {
    updated: 'Změny byly uloženy.',
    duplicate: 'Směna byla zduplikována.',
  },
  password: 'Heslo',
  email: 'E-mail',
  shift: {
    detail: 'Detail směny',
    from: 'od',
    to: 'do',
    days_worked: 'Odpracované dny',
    worked: 'Odpracováno',
    break: 'Přestávka',
    breaks: 'Přestávky',
    state: {
      APPROVED: 'SCHVÁLENO',
      UNAPPROVED: 'NEPŘIDĚLENO',
      ABSENCE: 'ABSENCE',
      EXCUSED: 'OMLUVENO',
    },
    state_cancel: 'zrušit',
    state_actual: 'aktuální stav',
  },
  list: {
    search: {
      term: 'Jméno',
      department: 'Oddělení',
      dateFrom: 'Od',
      dateTo: 'Do',
      date: 'Datum směny',
      orderBy: {
		title: 'Seřadit',
		dateAsc : 'Dle data vzestupně',
		dateDesc : 'Dle data sestupně',
		name : 'Dle jména'		
      },
    },
    head: {
      name: 'Jméno',
      time: 'Čas směny',
      state: 'Stav',
    },
    filters: 'Filtry',
    empty: 'Nebyly nalezeny žádné směny',
    emptyByFilter: 'Nebyly nalezeny žádné směny pro daný filtr. Zkuste upravit kritéria filtru pro zobrazení směn.',
  },
  loader: {
    loading: 'Načítám ...',
  },
  contactForm: {
    subject: 'Předmět zprávy',
    text: 'Sem napište Vaši zprávu',
    send: 'Odeslat',
    title: 'KONTAKTNÍ FORMULÁŘ',
    to: 'Komu',
    success: 'Děkujeme za Vaši zprávu. Odpovíme co nejdříve.',
    error: {
      subject: {
        empty: 'Předmět nesmí být prázdný',
        invalid: 'Předmět není validní',
      },
      text: {
        empty: 'Text nesmí být prázdný',
        invalid: 'Text obsahuje nevhodná slova',
      },
    },
  },
  confirmUnapprove: {
    text: 'Opravdu chcete vrátit směnu do stavu „Neschváleno“?',
    yes: 'ANO',
    no: 'NEVRACET',
  },
  customer: {
    name: {
      ha: 'HumanAgency s.r.o.',
      sks: 'SKS BUSINESS UNIT s.r.o.',
      si: 'Store Invent, s.r.o.',
      si_sk: 'Store Invent SK, s.r.o.',
    },
  },
  resetPassword: {
    forgottenLink: 'Zapomněl jsem heslo',
    password: 'Nové heslo',
    repeat: 'Potvrďte heslo',
    success: 'Heslo bylo změněno',
    noPasswordError: 'Heslo nesmí být prázdné',
    notEqualPasswordsError: 'Hesla v obou polích musí být stejná',
    noEmailError: 'Email nesmí být prázdný',
    requestSent: 'Odkaz na resetování hesla byl odeslán. Zkontrolujte Váš e-mail.',
    email: 'E-mail',
    emailDescription: 'Zadejte e-mail, který používáte jako přihlašovací jméno, bude na něj odeslána zpráva s odkazem na resetování hesla.',
    invalidEmailError: 'Zadaný řetězec není validní email',
  },
  copyright: 'používá informační systém pro pracovní agentury ',
  datepicker: {
    ok: 'Vybrat',
    cancel: 'Zrušit',
    clear: 'Smazat',
  },
  orders:{
    number: 'Číslo objednávky',
    date: 'Datum objednávky',
    department: 'Pracoviště',
    photos: 'Fotky',
    add_file : 'Přidat fotografii',
    del_file : '-',
  },
};
