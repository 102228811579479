import Vue from 'vue';
import axios from 'axios';
import {
    CONFIG
} from './config.js';

/**
 * AXIOS instance initialized with common options.
 */
Vue.prototype.$api = axios.create({
    baseURL: CONFIG.baseUrl,
    responseType: 'json',
    timeout: 0
});

/**
 * The method performs API request.
 *
 * @param {string} method - HTTP request method (GET, POST, PATCH, ...)
 * @param {string} endpoint - endpoint url (without domain, it's defined in $api above)
 * @param {function} callback - callback performed after succesful request, accepts one parameter 'response'
 * @param {object} options - additional axios request options
 */
Vue.prototype.$request = function (method, endpoint, callback, options) {
    options = options || {};
    options.method = method;
    options.url = endpoint;

    var self = this;

    return this.$api.request(options)
        .then(callback)
        .catch(function (error) {
            if (typeof error.response === 'undefined') {
                alert(error.message + "\n\nStack trace:\n" + error.stack);
                return;
            }

            var err = {
                type: "error-api-" + error.response.status,
                data: {
                    text: error.response.data.message
                }
            };

            switch (error.response.status) {
                case 401:
                    self.$store.commit('setTokenExpiredBackwardRoute', self.$router.currentRoute.path);
                    self.$store.commit('setToken', null);
                    self.$store.commit('setTokenExpiresIn', null);
                    self.$router.push({
                        path: '/login'
                    });

                    err.data.text = self.$t('error.api401');
                    err.persistent = true;
                    break;
                case 400:
                    var prefix = error.response.data.message.split(":")[0]

                    err.data.prefix = prefix;

                    if (prefix != CONFIG.error.prefix.wrongLogin) {
                        err.type = "error-modal";
                    }

                    switch (prefix) {
                        case CONFIG.error.prefix.recordWasChanged:
                            err.data.text = self.$t('error.version');
                            err.data.onclick = function () {
                                self.$ebus.$emit("msg:clear");
                                self.$loadShifts();
                            }
                            break;
                        case CONFIG.error.prefix.recordNotExists:
                            err.data.text = self.$t('error.deleted');

                            if (self.$options.name == "shift_detail") {
                                err.data.onclick = function () {
                                    self.$ebus.$emit("msg:clear");
                                    self.$loadShifts(() => self.$router.push('/list'));
                                };
                            } else {
                                err.data.onclick = function () {
                                    self.$ebus.$emit("msg:clear");
                                    self.$loadShifts();
                                }
                            }

                            break;
                        case CONFIG.error.prefix.wrongLogin:
                            err.data.text = self.$t('error.login');
                            break;
                        case CONFIG.error.prefix.noOpenPeriod:
                            var period = /''([^']+)/.exec(error.response.data.message)[1];
                            err.data.text = self.$t('error.closedPeriod', [period]);
                            break;
                        default:
                            break;
                    }

                    break;
                default:
                    err.type = "error-modal";
                    break;
            }

            self.$ebus.$emit("msg:new", err);
        });
}

/**
 * The method performs protected API request. It assumes that the token is stored in Vuex storage and this storage is accessible
 * from method context (this).
 *
 * @param {string} method - HTTP request method (GET, POST, PATCH, ...)
 * @param {string} endpoint - endpoint url (without domain, it's defined in $api above)
 * @param {function} callback - callback performed after succesful request, accepts one parameter 'response'
 * @param {object} options - additional axios request options
 */
Vue.prototype.$protected = function (method, endpoint, callback, options) {
    if (this.$store.getters.isTokenExpired) {
        this.$store.commit('setTokenExpiredBackwardRoute', this.$router.currentRoute.path);
        this.$store.commit('setToken', null);
        this.$store.commit('setTokenExpiresIn', null);

        this.$ebus.$emit("msg:new", {
            type: "error-api-401",
            data: {
                text: this.$t('error.api401'),
                persistent: true
            }
        });

        this.$router.push({
            path: '/login'
        });
    }

    options = options || {};

    var headers = options.headers || {};

    Object.assign(headers, {
        'Authorization': "Bearer " + this.$store.state.token
    });

    options.headers = headers;

    return this.$request(method, endpoint, callback, options);
}

/**
 * The method performs update of status of given shift.
 *
 * @param {string} id - id of shift to be updated
 * @param {string} version - version of shift to be updated
 * @param {string} status - new value of status
 * @param {function} callback - callback performed after succesful request, accepts one parameter 'response'
 */
Vue.prototype.$updateShiftState = function (id, version, status, callback) {
    this.$updateShift(id, version, {
        shift_state_simple: status
    }, callback);
}

/**
 * The method performs update of status of given shift.
 *
 * @param {string} id - id of shift to be updated
 * @param {string} version - version of shift to be updated
 * @param {string} data - payload
 * @param {function} callback - callback performed after succesful request, accepts one parameter 'response'
 */
Vue.prototype.$updateShift = function (id, version, data, callback) {
    data = Object.assign(data, {
        objversion: version
    });

    this.$protected("patch", CONFIG.api.endpoints.shifts,
        function (response) {
            // if the update request returns HTTP status 200 and empty data it means that the record with the given id doesn't exist
            if (response.status == 200 && response.data.length == 0) {
                throw {
                    response: {
                        status: 400,
                        data: {
                            message: CONFIG.error.prefix.recordNotExists
                        }
                    }
                };
            } else {
                callback(response);
            }
        }, {
            params: {
                ord_shift_id: "eq." + id
            },
            data: data,
            headers: {
                'Prefer': "return=representation"
            }
        });
}

/**
 * Loads list of shifts from API and result saves to the Vuex store.
 *
 * @param {function} callback - callback performed after the shifts are saved to store. Function has no parameters.
 */
Vue.prototype.$loadShifts = function (callback) {
    var self = this;

    var url = CONFIG.api.endpoints.shifts + "?";
    if (self.$store.getters.searchDateTo != null) {
        url = url + "shift_date=lte." + self.$store.getters.searchDateTo + "&";
    }

    if (self.$store.getters.searchDateFrom != null) {
        url = url + "shift_date=gte." + self.$store.getters.searchDateFrom + "&";
    }

    if (self.$store.getters.sorderShiftBy != null) {
        url = url + "order=" + self.$store.getters.sorderShiftBy;
    }

    this.$protected("get", url,
        function (response) {
            self.$store.commit('setShifts', response.data);

            if (callback) {
                callback();
            }
        }, {});
}

/**
 * Loads list of pauses to shift from API and result saves to the Vuex store.
 *
 * @param {string} shift_id - id of shift for apuses
 */
Vue.prototype.$loadShiftBreak = function (shift_id) {
    var self = this;

    var url = CONFIG.api.endpoints.shiftBreaks + "?ord_shift_id=eq." + shift_id ;
    this.$protected("get", url,
        function (response) {
            self.$store.commit('setShiftBreaks', response.data);
        }, {});
}


/**
 * Loads list of orders and result saves to the Vuex store.
 
 */
Vue.prototype.$loadOrders = function () {
    var self = this;

    //is needed render only orders in the plus or minus 10 days range from sysdate
    let  minusTenDays = new Date();
    let  plusTenDays = new Date();
    minusTenDays.setDate(minusTenDays.getDate() - parseInt(10));
    plusTenDays.setDate(plusTenDays.getDate() + parseInt(10));

    minusTenDays = minusTenDays.toISOString();
    plusTenDays = plusTenDays.toISOString();
 
    this.$protected("get", CONFIG.api.endpoints.orders + '&created_date=gte.' + minusTenDays + '&created_date=lte.' + plusTenDays,
        function (response) {
            self.$store.commit('setOrders', response.data);
        }, {});
}


/**
 * Loads list of deparments from API and result saves to the Vuex store.
 *
 */
Vue.prototype.$loadDepartments = function () {
    var self = this;

    this.$protected("POST", CONFIG.api.endpoints.departmentList,
        function (response) {
            var departmentsList = [];

            for (var key in response["data"]) {
                departmentsList.push(response["data"][key]["key"])
            }

            self.$store.commit("setAvailableDepartments", departmentsList);
        }, {
            data: {
                "view_": "evdo_order_shift",
                "column_": "department_id"
            }
        }
    );
}