<template>
    <b-row>
        <b-col cols="12" class="fixed-bottom button_row text-center">
            <slot></slot>
        </b-col>
    </b-row>
</template>


<script>
export default {
    data: function() {
        return {};
    }
};
</script>

<style scoped lang="scss">
@import "src/scss/main";

.button_row {
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #fff;
    box-shadow: 0 5px 5px 5px #b3b3b3;
    min-height: $button-row-height;
    justify-content: flex-end;

    button {
        margin: 5px;
    }
}

@include media-breakpoint-down(xxs) {
    //@include media-breakpoint-down(xs) {
    .button_row {
        justify-content: center;
        margin-top: 10px;
        font-size: 80%;

        button {
            font-size: 0.7rem;
        }
    }
}

@include media-breakpoint-down(xxxxs) {
    .button_row {
        flex-direction: column !important;
    }
}
</style>
