<template>
    <b-row justify-content-center class="shift_list" v-cloak>
        <div v-if="isAnyAdvancedFilterEnabled && isFilterExpanded" class="filter-overlay">
            <b-navbar type="dark" variant="primary" sticky>
                <b-navbar-brand href="#">
                    <img @click="closeFilter()" class="icon filter_close_button" :src="'/img/' + CUSTOMER + '/close_14px.png'" v-bind:alt="$t('button.close')" />
                    {{ $t("list.filters") }}
                </b-navbar-brand>
            </b-navbar>

            <ListAdvancedFilter v-on:list-filter-apply="applyAdvancedFilter" ref="advancedFilter" />
        </div>

        <b-col>
            <b-row class="text-left filter-row">
                <b-col v-if="isTermFilterEnabled">
                    <b-input-group>
                        <template v-slot:prepend>
                            <b-input-group-text>
                                <img :src="'/img/' + CUSTOMER + '/search_24px.png'" />
                            </b-input-group-text>
                        </template>

                        <template v-slot:append>
                            <b-input-group-text>
                                <img v-show="searchTerm" @click="searchTerm = null" :src="'/img/' + CUSTOMER + '/close_dark_14px.png'" class="clear_button" />
                            </b-input-group-text>
                        </template>

                        <b-form-input v-model="searchTerm"></b-form-input>
                    </b-input-group>
                </b-col>

                <b-col v-if="isAnyAdvancedFilterEnabled" class="col-auto">
                    <b-button @click="switchFilter()" class="filter-toggler" variant="outline-dark">
                        <b-img :src="'/img/' + CUSTOMER + '/tune_24px.png'" alt="show filter" />
                    </b-button>
                </b-col>

                <template v-if="isAnyAdvancedFilterUsed">
                    <div class="w-100"></div>
                    <b-col>
                        <div class="badges d-flex flex-wrap">
                            <b-badge
                                v-if="searchDateFrom && !yesterday.isSame(searchDateFrom)"
                                @click="expandFilter()"
                                pill
                                href="#"
                                variant="light"
                            >{{ $t("list.search.dateFrom") }}: {{ searchDateFrom | moment("D. M. YYYY") }}</b-badge>
                            <b-badge
                                v-if="searchDateTo && !tomorrow.isSame(searchDateTo)"
                                @click="expandFilter()"
                                pill
                                href="#"
                                variant="light"
                            >{{ $t("list.search.dateTo") }}: {{ searchDateTo | moment("D. M. YYYY") }}</b-badge>

                            <template v-if="numberOfUsedFilerCriteria <= 5">
                                <b-badge v-for="d in searchDepartment" :key="d" @click="expandFilter()" pill href="#" variant="light">{{ d }}</b-badge>
                            </template>
                            <b-badge
                                v-else
                                @click="expandFilter()"
                                pill
                                href="#"
                                variant="light"
                            >{{ $t('list.search.department') }}: {{ searchDepartment.length }}</b-badge>
                        </div>
                    </b-col>
                </template>
            </b-row>

            <b-row class="shift_list_head">
                <b-col cols="5">{{ $t('list.head.name') }}</b-col>
                <b-col cols="3">{{ $t('list.head.time') }}</b-col>
                <b-col cols="4">{{ $t('list.head.state') }}</b-col>
            </b-row>

            <b-row v-if="!shiftsCount">
                <b-col class="py-3 text-muted">
                    <em>{{ $t('list.empty') }}</em>
                </b-col>
            </b-row>
            <b-row v-else-if="!shiftsCountByFilter">
                <b-col class="py-3 text-muted">
                    <em>{{ $t('list.emptyByFilter') }}</em>
                </b-col>
            </b-row>
            <template v-else v-for="s in shifts">
                <Shift v-if="isSearched(s)" :key="s.ord_shift_id" :shift="s" />
            </template>
        </b-col>
    </b-row>
</template>


<script>
import Shift from "@/components/Shift.vue";
import ListAdvancedFilter from "@/components/ListAdvancedFilter.vue";
import { CONFIG } from "../config.js";
import accents from "remove-accents";

export default {
    data: function() {
        return {
            isFilterExpanded: false
        };
    },
    components: { Shift, ListAdvancedFilter },
    mounted: function() {
        if (!this.searchFormInitDone) {
            this.searchDateFrom = this.yesterday.format();
            this.searchDateTo = this.tomorrow.format();
            this.$store.commit("updateListFilter", {
                to: this.searchDateTo,
                from: this.searchDateFrom
            });
            this.$store.commit("setSearchFormInitDone", true);
        }

        this.$loadShifts();
        this.$loadDepartments();
    },
    computed: {
        searchFormInitDone() {
            return this.$store.getters.searchFormInitDone;
        },
        filter() {
            return this.$store.getters.listFilter;
        },
        searchTerm: {
            get: function() {
                return this.filter.term;
            },
            set: function(value) {
                this.$store.commit("updateListFilter", { term: value });
            }
        },
        searchDepartment: {
            get: function() {
                return this.filter.departments;
            },
            set: function(value) {
                this.$store.commit("updateListFilter", { departments: value });
            }
        },
        searchDateFrom: {
            get: function() {
                return this.filter.from;
            },
            set: function(value) {
                this.$store.commit("updateListFilter", { from: value });
            }
        },
        searchDateTo: {
            get: function() {
                return this.filter.to;
            },
            set: function(value) {
                this.$store.commit("updateListFilter", { to: value });
            }
        },
        shifts() {
            return this.$store.getters.shifts;
        },
        isTermFilterEnabled() {
            return !!CONFIG.enableTermFilter;
        },
        isAnyAdvancedFilterEnabled() {
            return (
                this.isDepartmentFilterEnabled || this.isDateRangeFilterEnabled
            );
        },
        isAnyAdvancedFilterUsed() {
            return (
                this.searchDateTo ||
                this.searchDateFrom ||
                this.searchDepartment.length
            );
        },
        isManagerUser() {
            return true;
        },
        tomorrow() {
            return this.$moment
                .utc()
                .startOf("day")
                .add(1, "days");
        },
        yesterday() {
            return this.$moment
                .utc()
                .startOf("day")
                .subtract(1, "days");
        },
        numberOfUsedFilerCriteria() {
            return (
                this.searchDepartment.length +
                (this.searchDateFrom ? 1 : 0) +
                (this.searchDateTo ? 1 : 0)
            );
        },
        shiftsCount: function() {
            return this.shifts.length;
        },
        shiftsCountByFilter: function() {
            return this.shifts.filter(n => this.isSearched(n)).length;
        }
    },
    methods: {
        isSearched: function(shift) {
            if (
                !this.searchTerm &&
                !this.searchDepartment.length &&
                !this.searchDateFrom &&
                !this.searchDateTo
            ) {
                return true;
            }
            // searching by term
            if (
                this.isTermFilterEnabled &&
                this.searchTerm &&
                accents
                    .remove(shift.person_name.toLowerCase())
                    .search(accents.remove(this.searchTerm.toLowerCase())) < 0
            ) {
                return false;
            }
            // searching by department
            if (
                this.isDepartmentFilterEnabled &&
                this.searchDepartment.length &&
                !this.searchDepartment.includes(shift.department_id)
            ) {
                return false;
            }

            return true;
        },
        applyAdvancedFilter: function(filter) {
            this.$ebus.$emit("ajax:start");
            this.$store.commit("setListFilter", filter);
            this.$loadShifts(() => this.closeAppliedFilter());
        },
        isDepartmentFilterEnabled: function() {
            return this.$refs.advancedFilter.isDepartmentFilterEnabled;
        },
        isDateRangeFilterEnabled: function() {
            return this.$refs.advancedFilter.isDateRangeFilterEnabled;
        },
        switchFilter: function() {
            if (this.isFilterExpanded) {
                this.closeFilter();
            } else {
                this.expandFilter();
            }
        },
        closeAppliedFilter: function() {
            this.isFilterExpanded = false;
            this.$ebus.$emit("ajax:finished");
        },
        closeFilter: function() {
            this.isFilterExpanded = false;
        },
        expandFilter: function() {
            this.isFilterExpanded = true;
        }
    }
};
</script>

<style lang="scss">
@import "src/scss/variables";

.vdatetime {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.vdatetime-overlay {
    z-index: $z-index-datepicker-overlay !important;
}

.vdatetime-popup {
    z-index: $z-index-datepicker-popup !important;
}

.vdatetime-input {
    width: 125px;
    text-align: center;
    cursor: pointer;
    background-color: white;
}

.vdatetime-icon {
    margin-right: 10px;
    line-height: 0;
    cursor: pointer;
}

.vdatetime-clear {
    margin-left: 10px;
    line-height: 0;

    button {
        border: none;
        background-color: transparent;
        padding: 0 !important;
        margin: 0 !important;
        line-height: 0;
    }
}

.datepicker-group {
    display: inline-block;
    margin: 0 0 10px 0 !important;

    input {
        text-align: center;
    }
}

.col-form-label {
    font-weight: 700;
    margin-bottom: 15px;
}
</style>

<style scoped lang="scss">
@import "src/scss/variables";

.shift_list {
    font-size: 14px;
    text-align: center;
}

.shift_list_head {
    font-weight: 700;
    font-size: 12px;
    margin-top: 30px;
}

.shift_list_date {
    font-weight: 400;
    color: $gray-500;
    font-size: 12px;
}

.shift_list_state {
    font-weight: 700;
}

.shift_list_search {
    padding-bottom: 10px;
    padding-top: 10px;
    background-color: $gray-200;
}

.shift_list_reset_button {
    text-align: left;
    margin-top: 9px;
    margin-left: -15px;
}

.filter-row {
    background-color: $gray-200;
    padding: 10px;
    margin-top: -$content-top-padding;

    .clear_button {
        cursor: pointer;
    }

    .input-group {
        .input-group-text {
            background-color: #fff;
        }

        input {
            border-right: none;
            border-left: none;
        }
    }
}

.filter-overlay {
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: $z-index-list-filter-overlay;
    top: 0;
    left: 0;
    background-color: #fff;
    overflow: auto;

    .container {
        margin-bottom: $button-row-height + 10px;
    }

    .form-group {
        margin-top: 30px;
        margin-bottom: 0px;
    }
}

.filter_close_button {
    cursor: pointer;
    margin-right: 20px;
    width: 14px;
    height: 14px;
}

.badges {
    .badge {
        margin-top: 10px;
        margin-right: 5px;
        padding: 5px 8px;
        font-weight: 100;
        border: 1px solid $secondary;
    }
}
</style>
