<template>
    <div class="holder" id="login">
        <div class="row justify-content-center">
            <div class="col-11 col-lg-4 col-xl-4">
                <b-form>
                    <div class="form-group row">
                        <label for="id_username">{{ $t("email") }}</label>
                        <input class="form-control" v-model="username" type="text" required autofocus />
                    </div>
                    <div class="form-group row">
                    <label for="id_password">{{ $t("password") }}</label>
                        <input class="form-control" v-model="password" type="password" required />
                    </div>
                    <div class="form-group row">
                        <button @click.prevent="login" class="btn btn-lg btn-primary btn-block btn-signin" type="submit">
                            {{ $t("button.login") }}
                        </button>
                    </div>
                </b-form>
                <p class="text-center">
                    <a href="" @click.prevent="forgottenPassword">{{ $t("resetPassword.forgottenLink") }}</a>
                </p>
            </div>
        </div>
        <div class="row copyright justify-content-center fixed-bottom">
            <div class="col col-10 text-center">
                <strong>{{ $t("customer.name." + CUSTOMER ) }}</strong> {{ $t("copyright" ) }} <a href="https://www.blahovec.cz" target="_blank">Bláhovec.cz</a>
            </div>
        </div>
    </div>
</template>


<script>
import { CONFIG } from '../config.js';

export default {
    data: function() {
        return {
            username: null,
            password: null
        }
    },
    methods: {
        login: function() {
            var self = this;

            this.$store.commit('setToken', null);
            this.$store.commit('setTokenExpiresIn', null);

            this.$request("post", CONFIG.api.endpoints.token,
                function(response) {
                    var token = response.data[0]["token"];
                    self.$store.commit('setToken', token);
                    self.$store.commit('setTokenExpiresIn', Date.now() + CONFIG.api.tokenExpiration);

                    var path = self.$store.state.tokenExpiredBackwardRoute || CONFIG.afterLoginRoute;
                    self.$router.push({ path: path });
                },
                {
                    data:
                    {
                        "login_": this.username,
                        "domain_": CONFIG.tokenDomain,
                        "password_": this.password
                    }
                }
            )
        },
        forgottenPassword: function() {
            this.$router.push({path: "/forgottenPassword/"})
        },
    }
};
</script>


<style scoped lang="scss">
.copyright {
    font-size: 12px;
    padding-bottom: 10px;
}
</style>
