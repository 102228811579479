<template>
    <b-col cols="11" v-if="message">
        <b-alert show variant="danger" v-html="'[' + message.data.code + ']: ' + message.data.text" />
    </b-col>
</template>


<script>
export default {
    name: "error-default",
    props: ['message']
};
</script>


<style scoped lang="scss">

</style>
