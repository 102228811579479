<template>
    <b-row align-v="center" class="contact_form">
        <b-col cols="12">
            <b-modal class="modal_confirm_unapproved" centered
                v-model="showConfirm"
                :ok-title="$t('button.ok')"
                :hide-header="true"
                :ok-only="true"
                @hidden="$router.go(-1)">
                {{ $t('contactForm.success') }}
            </b-modal>

            <b-form @submit="onSubmit">
                <b-form-group
                    :state="isSubjectValid"
                    :invalid-feedback="subjectInvalidFeedback">
                        <b-form-input id="contact_subject"
                                      type="text"
                                      v-model="subject"
                                      :state="isSubjectValid"
                                      :placeholder="$t('contactForm.subject')"
                                      v-on:input="subjectChanged = (subject != null)">
                        </b-form-input>
                </b-form-group>

                <b-form-group
                    class="contact_text"
                    label-for="contact_text"
                    :state="isTextValid"
                    :invalid-feedback="textInvalidFeedback">
                        <b-form-textarea id="contact_text"
                                         v-model="text"
                                         :placeholder="$t('contactForm.text')"
                                         :rows="10"
                                         :state="isTextValid"
                                         :no-resize="true"
                                         v-on:input="textChanged = (text != null)">
                        </b-form-textarea>
                </b-form-group>

                <b-col cols="12" id="button_row" class="fixed-bottom text-right">
                    <b-row>
                        <b-col cols="12">
                            <b-button type="submit" variant="primary" :disabled="!this.isSubjectValid || !this.isTextValid">{{ $t('contactForm.send') }}</b-button>
                        </b-col>
                    </b-row>
                </b-col>
            </b-form>
        </b-col>
    </b-row>
</template>


<script>
import { CONFIG } from "../config.js"

export default {
    data: function() {
        return {
            subject: null,
            text: null,
            subjectChanged: false,
            textChanged: false,
            showConfirm: false
        }
    },
    mounted: function() {
        this.$ebus.$emit('menu:update', {component: 'nav-contact'});
    },
    destroyed: function() {
        this.$ebus.$emit('menu:reset');
    },
    methods: {
        onSubmit: function(evt) {
            evt.preventDefault();
            this.$ebus.$emit("msg:clear");

            if (this.isSubjectValid && this.isTextValid) {
                this.$protected("post", CONFIG.api.endpoints.email,
                    () => {
                        this.resetForm();
                        this.showConfirm = true;
                    },
                    {
                        data: {
                            request_text_: self.text,
                            request_subject_: self.subject,
                            source_url_: window.location.origin + self.$store.state.previousRoute,
                        }
                    })
            }
        },
        resetForm: function() {
            this.text = null;
            this.subject = null;
        }
    },
    watch: {
        text: function(v) {
            if (v === null) {
                this.textChanged = false;
            }
        },
        subject: function(v) {
            if (v === null) {
                this.subjectChanged = false;
            }
        }
    },
    computed: {
        subjectInvalidFeedback: function() {
            if (this.subjectChanged) {
                if (!this.subject) {
                    return this.$t("contactForm.error.subject.empty");
                }
            }

            return '';
        },
        isSubjectValid: function() {
            return true;
        },
        textInvalidFeedback: function() {
            if (this.textChanged) {
                if (!this.text) {
                    return this.$t("contactForm.error.text.empty");
                } else if (!this.isTextValid) {
                    return this.$t("contactForm.error.text.invalid");
                }
            }

            return '';
        },
        isTextValid: function() {
            if (!this.textChanged) {
                return null;
            }

            return this.text != null && this.text.length > 0;
        },
    }
};
</script>


<style scoped lang="scss">
#button_row {
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #fff;
    box-shadow: 0 5px 5px 5px #b3b3b3;
    height: 60px;
}

.contact_text {
	margin-bottom: 70px;
}
</style>
