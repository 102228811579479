<template>
    <b-row v-if="shift">
        <b-modal
            class="modal_confirm_unapproved"
            centered
            v-model="showConfirm"
            :ok-title="$t('confirmUnapprove.yes')"
            :cancel-title="$t('confirmUnapprove.no')"
            :body-text-variant="$t('confirmUnapprove.text')"
            cancel-variant="light"
            :hide-header="true"
            @ok="shiftUnapprove"
        >{{ $t('confirmUnapprove.text') }}</b-modal>

        <b-col class="shift_detail" cols="12" lg="8">
            <ShiftDetailForm :shift="shift" v-on:duplicate="duplicateShift" v-on:shift-form-update="d => formData = d" :clone="false">
                <template #buttons :shift="shift">
                    <template v-if="isUnapproved">
                        <b-button class="button_row_button" variant="danger" @click="shiftAbsence">{{ $t("button.absence") }}</b-button>
                        <b-button class="button_row_button" variant="secondary" @click="shiftExcuse">{{ $t("button.excuse") }}</b-button>
                        <b-button class="button_row_button" variant="success" @click="shiftApprove">{{ $t("button.approve") }}</b-button>
                    </template>
                    <span v-else class="cancel_link" @click="showConfirm = true">
                        <span class="state_description">{{ $t('shift.state_actual') }}:&nbsp;</span>
                        <b-link class="btn btn-link btn-lg" :class="(shiftState.toLowerCase()) + '-btn'">{{ $t("shift.state." + shiftState) }}</b-link>
                        &nbsp;({{ $t('shift.state_cancel') }}&nbsp;
                        <img :src="'/img/' + CUSTOMER + '/close_dark_14px.png'" />)
                    </span>
                </template>
            </ShiftDetailForm>
        </b-col>
    </b-row>
    <b-row v-else>
        <b-alert show class="col-12" variant="danger">{{ $t("error.shiftNotExists", [$router.currentRoute.params.id]) }}</b-alert>
    </b-row>
</template>


<script>
import ShiftDetailForm from "@/components/ShiftDetailForm.vue";

export default {
    props: ["id"],
    data: function() {
        return {
            showConfirm: false,
            formData: {}
        };
    },
    components: { ShiftDetailForm },
    mounted: function() {
        if (this.shift == null) {
            return;
        }

        this.$ebus.$emit("menu:update", {
            component: "nav-detail",
            data: { person_name: this.shift.person_name }
        });
    },
    destroyed: function() {
        this.$ebus.$emit("menu:reset");
    },
    computed: {
        shift: {
            get: function() {
                return this.$store.getters.getShiftById(this.id);
            },
            set: function(value) {
                this.$store.commit("updateShiftById", {
                    id: this.id,
                    data: value
                });
            }
        },
        shiftId: function() {
            return this.shift.ord_shift_id;
        },
        shiftVersion: function() {
            return this.shift.objversion;
        },
        shiftState: function() {
            return this.shift.shift_state_simple || "APPROVED";
        },
        isUnapproved: function() {
            return this.shiftState == "UNAPPROVED";
        },
        hoursPaid: function() {
            return this.$moment.duration(this.formData.hour_paid || 0, "hours");
        }
    },
    methods: {
        shiftApprove: function() {
            this.updateShift("APPROVED");
        },
        shiftAbsence: function() {
            this.formData.stars = null;
            this.updateShift("ABSENCE");
        },
        shiftExcuse: function() {
            this.formData.stars = null;
            this.updateShift("EXCUSED");
        },
        shiftUnapprove: function() {
            this.updateShiftState("UNAPPROVED");
        },
        updateShiftState: function(status) {
            this.$updateShiftState(
                this.shiftId,
                this.shiftVersion,
                status,
                r => (this.shift = r.data[0])
            );
        },
        updateShift: function(status) {
            this.$updateShift(
                this.shiftId,
                this.shiftVersion,
                {
                    shift_date: this.shift.shift_date,
                    shift_time_from: this.formData.from,
                    shift_time_until: this.formData.to,
                    shift_state_simple: status || "APPROVED",
                    stars : this.formData.stars,
                    hour_paid: this.hoursPaid.asHours()
                },
                r => (this.shift = r.data[0])
            );
        },
        duplicateShift: function() {
            this.$router.push("/clone/" + this.shiftId);
        }
    }
};
</script>


<style scoped lang="scss">
@import "src/scss/main";

.shift_detail {
    margin-bottom: 80px;
}

@include media-breakpoint-down(xxs) {
    .shift_detail {
        margin-bottom: 0;
    }
}

.absence-btn {
    color: $red;
    padding-left: 0px;
    padding-right: 0px;
}

.absence-btn:hover {
    color: #cf6700;
}

.approved-btn {
    color: $primary;
    padding-left: 0px;
    padding-right: 0px;
}

.excused-btn {
    color: #4a4a4a;
}

.cancel_link {
    cursor: pointer;
}
</style>
