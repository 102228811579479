import VueRouter from 'vue-router';
import store from './store';
import Login from '@/components/Login';
import List from '@/components/List';
import ShiftDetail from '@/components/ShiftDetail';
import ContactForm from '@/components/ContactForm';
import ForgottenPasswordForm from '@/components/ForgottenPasswordForm';
import ResetPasswordForm from '@/components/ResetPassword';
import ShiftClone from '@/components/ShiftClone';
import Orders from '@/components/Orders'
import OrderDetail from '@/components/OrderDetail'

const routes = [
    { path: '/', redirect: '/login' },
    { path: '/login', component: Login },
    { path: '/list', component: List, meta: { requiresAuth: true } },
    { path: '/orders', component: Orders, meta: { requiresAuth: true } },
    { path: '/orderdetail/:id', component: OrderDetail, meta: { requiresAuth: true } },
    { path: '/detail/:id', component: ShiftDetail, props: true, meta: { requiresAuth: true } },
    { path: '/clone/:parentId', component: ShiftClone, props: true, meta: { requiresAuth: true } },
    { path: '/contact', component: ContactForm },
    { path: '/forgottenPassword', component: ForgottenPasswordForm },
    { path: '/reset-password/:token', component: ResetPasswordForm },
]
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: routes
})

router.beforeEach((to, from, next) => {
    //store.commit('setPreviousRoute', from.path);

    if(to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.isLoggedIn) {
            next();
            return;
        }

        next('/login');
    } else {
        next();
    }
})

export default router;