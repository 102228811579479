<template>
    <b-navbar  type="dark" variant="primary" sticky>
        <b-navbar-brand href="#" @click="$router.go(-1)">
            <img class="icon menu_back_button" :src="'/img/' + CUSTOMER + '/arrow_back_24px.png'" v-bind:alt="$t('button.back')" />
            {{ $t('contactForm.title') }}
        </b-navbar-brand>

        <div></div>
    </b-navbar>
</template>


<script>
export default {
    name: "nav-contact",
    props: ["data"]
};
</script>


<style scoped lang="scss">

</style>
