<template>
    <b-row align-v="center" align-h="center" v-if="display" class="ajax_loader_outer">
        <svg width="120" height="30" viewBox="0 0 120 30" xmlns="http://www.w3.org/2000/svg" fill="#a3a3a3">
            <circle cx="15" cy="15" r="15">
                <animate attributeName="r" from="15" to="15"
                            begin="0s" dur="0.8s"
                            values="15;9;15" calcMode="linear"
                            repeatCount="indefinite" />
                <animate attributeName="fill-opacity" from="1" to="1"
                            begin="0s" dur="0.8s"
                            values="1;.5;1" calcMode="linear"
                            repeatCount="indefinite" />
            </circle>
            <circle cx="60" cy="15" r="9" fill-opacity="0.3">
                <animate attributeName="r" from="9" to="9"
                            begin="0s" dur="0.8s"
                            values="9;15;9" calcMode="linear"
                            repeatCount="indefinite" />
                <animate attributeName="fill-opacity" from="0.5" to="0.5"
                            begin="0s" dur="0.8s"
                            values=".5;1;.5" calcMode="linear"
                            repeatCount="indefinite" />
            </circle>
            <circle cx="105" cy="15" r="15">
                <animate attributeName="r" from="15" to="15"
                            begin="0s" dur="0.8s"
                            values="15;9;15" calcMode="linear"
                            repeatCount="indefinite" />
                <animate attributeName="fill-opacity" from="1" to="1"
                            begin="0s" dur="0.8s"
                            values="1;.5;1" calcMode="linear"
                            repeatCount="indefinite" />
            </circle>
        </svg>
    </b-row>
</template>

<script>
export default {
    props: ["display"]
};
</script>

<style scoped lang="scss">
@import "src/scss/variables";

.ajax_loader_outer {
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100%;
	margin: 0;
	background-color: rgba($light, .5);
	color: $primary;
	z-index: $z-index-ajax-loader;
}

.ajax_loader_inner {
	opacity: 1;
	font-size: 40px;
	font-weight: 700;
}
</style>
