<template>
    <b-row :class="shift.shift_state_simple" align-v="center" class="shift_list_row">
        <b-modal class="modal_confirm_unapproved" centered
            v-model="showConfirm"
            :ok-title="$t('confirmUnapprove.yes')"
            :cancel-title="$t('confirmUnapprove.no')"
            :body-text-variant="$t('confirmUnapprove.text')"
            cancel-variant="light"
            :hide-header="true"
            @ok="shiftUnapprove">
                {{ $t('confirmUnapprove.text') }}
        </b-modal>

        <b-col cols="5" @click="goToDetail" class="name_and_date">
            <div class="shift_list_name">{{ shift.person_name }}</div>
            <div class="shift_list_date">{{ shiftFrom | moment("D.M.YYYY") }}</div>
        </b-col>
        <b-col cols="3" @click="goToDetail" class="time">
            {{ shiftFrom | moment("HH:mm") }}<br/>
            {{ shiftTo | moment("HH:mm") }}
        </b-col>
        <b-col cols="4" class="shift_list_state">
            <b-button-group size="sm" v-if="shift.shift_state_simple == 'UNAPPROVED'">
                <b-button variant="success" @click="shiftApprove">{{ $t("button.approve") }}</b-button>
            </b-button-group>
            <span v-else @click="showConfirm = true" class="state">{{ $t("shift.state." + shift.shift_state_simple) }}</span>
        </b-col>
    </b-row>
</template>


<script>

export default {
    props: ['shift'],
    data: function() {
        return {
            showConfirm: false
        }
    },
    methods: {
        goToDetail: function() {
            this.$router.push({path: "/detail/" + this.shiftId})
        },
        shiftApprove: function() {
            this.updateShiftState('APPROVED');
        },
        shiftAbsence: function() {
            this.updateShiftState('ABSENCE');
        },
        shiftUnapprove: function() {
            this.updateShiftState('UNAPPROVED');
        },
        updateShiftState: function(status) {
            this.$updateShiftState(this.shiftId, this.shiftVersion, status,
                r => {
                    this.$store.commit('updateShiftById', { id: this.shift.ord_shift_id, data: r.data[0] });
                    this.$ebus.$emit("msg:new", {type: "message-success", data: {text: this.$t("message.updated")}})
                });
        },
        parseShiftDateTime: function (time) {
            return this.$moment(this.shift.shift_date + " " + time, "YYYY-MM-DD HH:mm:ss");
        }
    },
    computed: {
        shiftId: function() {
            return this.shift.ord_shift_id;
        },
        shiftVersion: function() {
            return this.shift.objversion;
        },
        shiftFrom: function() {
            return this.parseShiftDateTime(this.shift.shift_time_from);
        },
        shiftTo: function() {
            return this.parseShiftDateTime(this.shift.shift_time_until);
        }
    }
};
</script>

<style scoped lang="scss">
@import "src/scss/variables";

.shift_list_row .name_and_date {
    text-align: left;
}

.shift_list_row > div:not(.modal_confirm_unapproved) {
    cursor: pointer;
}

.shift_list_row .time {
    color: $gray-500;
}

.shift_list_row {
    border-left: transparent 4px solid;
    line-height: 1.2;
    padding-top: 10px;
    padding-bottom: 10px;
}

.shift_list_row {
    border-bottom: $gray-200 1px solid;
}

.shift_list_row.APPROVED {
    border-left-color: $primary;
}

.shift_list_row.APPROVED .state {
    color: $primary;
}

.shift_list_row.ABSENCE {
    border-left-color: $warning;
}

.shift_list_row.ABSENCE .state {
    color: $warning;
}
</style>
