<template>
  <b-row no-gutters align-v="center" class="order" @click="goToDetail(order.objid)">
    <b-col>
      {{ parseOrderDateTime(order.created_date) }}
    </b-col>
    <b-col>
      {{ order.department_id }}
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: ['order'],
  methods: {
    parseOrderDateTime: function(time) {
      return this.$moment.utc(time).format('D.M.YYYY');
    },
    goToDetail: function(orderID) {
      this.$router.push({ path: '/orderdetail/' + orderID });
    },
  },
};
</script>

<style scoped lang="scss">
@import 'src/scss/variables';
.order {
  color: $gray-500;
  border-bottom: $gray-200 1px solid;
  border-left: transparent 4px solid;
  line-height: 1.2;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: left;
  font-weight: normal !important;
  font-size: 12px;
}
</style>
