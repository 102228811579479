// common configuration
var CUSTOMER = process.env.VUE_APP_CUSTOMER;

var config = {
  api: {
    endpoints: {
      token: 'rpc/platform_jwt_token_generate',
      shifts: 'evdo_order_shift',
      person: 'person',
      daysWorked: 'rpc/wa_person_get_days_worked',
      photo: 'rpc/asw_person_get_photo',
      email: 'rpc/asw_contact_form_send_email',
      calcHourWithAutoBreak: 'rpc/evdo_order_shift_calculate_shift_hour_auto',
      calcHourWithOneBreak: 'rpc/evdo_order_shift_times_calculate_hour_paid',
      calcHourWithMoreBreak: 'rpc/evdo_order_shift_calculate_shift_hour_break',
      forgottenPassword: 'rpc/asw_send_change_password_link',
      shiftButtons: 'wa_order_dep_shift_cycle',
      resetPassword: 'rpc/asw_change_password_by_jwt_token',
      duplicateShift: 'rpc/asw_order_shift_person_duplicate',
      departmentList: 'rpc/lov_key',
      shiftBreaks: 'evdo_order_shift_break',
      orders: 'evdo_order?order=created_date.asc',
      upload_doc: 'rpc/evdo_order_doc_upload',
      getDocToOrder: 'evdo_order_doc',
      getDocToOrderBin: 'rpc/evdo_get_bin_object',
    },
    tokenExpiration: 4 * 3600 * 1000, // 4 hours
  },
  afterLoginRoute: '/list',
  error: {
    prefix: {
      recordNotExists: 'RECORD_NOT_EXISTS',
      recordWasChanged: 'RECORD_WAS_CHANGED',
      wrongLogin: 'WRONG_USER_OR_PASSWORD',
      noOpenPeriod: 'NO_OPEN_PERIOD',
    },
  },
  OneBreakMode: false,
  enableTermFilter: true,
  enableDepartmentFilter: false,
  enableDateRangeFilter: false,
  enableShiftClone: false,
  displayStars: false
};

if (CUSTOMER == 'ha') {
  Object.assign(config, {
    baseUrl: 'https://api.blahovec.cz/',
    tokenDomain: 'humanagency.cz',
    resetPasswordToken: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoid2ViYmljcyIsImRvbWFpbiI6Imh1bWFuYWdlbmN5LmN6IiwiZXhwIjoyMDAwMDAwMDAwfQ.CQXYGSFW2SPMB6kI_Guox23x8MzA5q9JYc0r5I0jBAw',
  });
}

if (CUSTOMER == 'sks') {
  Object.assign(config, {
    baseUrl: 'https://api.alpha.blahovec.cz',
    tokenDomain: 'sksbu.cz',
    resetPasswordToken: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiZWtyX3dzIiwiZG9tYWluIjoiZW1vbmFrcm9uaS5jeiIsImV4cCI6MjAwMDAwMDAwMH0.TyZ_HcfU2uKU9nEqV6D1JzE2e88kU6ePsxEUVJ_1i4s',
    OneBreakMode: true,
    enableDepartmentFilter: true,
    enableDateRangeFilter: true,
    enableShiftClone: true,
  });
}

if (CUSTOMER == 'si') {
  Object.assign(config, {
    baseUrl: 'https://api.alpha.blahovec.cz',
    tokenDomain: 'storebrigady.cz',
	// resetPasswordToken: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoic2lfd2ViIiwiZG9tYWluIjoic3RvcmUtZ3JvdXAuZXUiLCJleHAiOjIwMDAwMDAwMDB9.QFzj_skWI_Yy9pAJQ1YhtBkEMicQ2vRxi3fFt9Tw_y4",
	resetPasswordToken: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoic2lfd2ViIiwiZG9tYWluIjoic3RvcmVicmlnYWR5LmN6IiwiZXhwIjoxOTAwNzc5MzQ4fQ.uHkCmKpFvBpRSDXEms-2k1sZK1-q1xDvP-o9QpyBuT4",
    OneBreakMode: true,
    enableDepartmentFilter: true,
    enableDateRangeFilter: true,
    enableShiftClone: true,
  });
}

if (CUSTOMER == 'si_sk') {
  Object.assign(config, {
    baseUrl: 'https://api.alpha.blahovec.cz',
    tokenDomain: 'storebrigady.sk',
    resetPasswordToken: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoic2lza193ZWIiLCJkb21haW4iOiJzdG9yZWJyaWdhZHkuc2siLCJleHAiOjIwMDAwMDAwMDB9.ivQh8VkCcJ07WmK7rZxthg0E2AHkrJiAi0ixjP2Tp00',
    OneBreakMode: false,
    enableDepartmentFilter: true,
    enableDateRangeFilter: true,
    enableShiftClone: true,
  });
}

if (process.env.NODE_ENV == 'production' && CUSTOMER == 'ha') {
  Object.assign(config, {
    baseUrl: 'https://api.blahovec.cz/',
    resetPasswordToken: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoid2ViYmljcyIsImRvbWFpbiI6Imh1bWFuYWdlbmN5LmN6IiwiZXhwIjoyMDAwMDAwMDAwfQ.CQXYGSFW2SPMB6kI_Guox23x8MzA5q9JYc0r5I0jBAw',
  });
}

if (process.env.NODE_ENV == 'production' && CUSTOMER == 'sks') {
  Object.assign(config, {
    baseUrl: 'https://api.alpha.blahovec.cz',
		// resetPasswordToken: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoic2lfd2ViIiwiZG9tYWluIjoic3RvcmUtZ3JvdXAuZXUiLCJleHAiOjIwMDAwMDAwMDB9.QFzj_skWI_Yy9pAJQ1YhtBkEMicQ2vRxi3fFt9Tw_y4"
		resetPasswordToken: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoic2lfd2ViIiwiZG9tYWluIjoic3RvcmVicmlnYWR5LmN6IiwiZXhwIjoxOTAwNzc5MzQ4fQ.uHkCmKpFvBpRSDXEms-2k1sZK1-q1xDvP-o9QpyBuT4"
  });
}

if (process.env.NODE_ENV == 'production' && CUSTOMER == 'si') {
  Object.assign(config, {
    baseUrl: 'https://api.alpha.blahovec.cz',
    resetPasswordToken: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoic2lfd2ViIiwiZG9tYWluIjoic3RvcmUtZ3JvdXAuZXUiLCJleHAiOjIwMDAwMDAwMDB9.QFzj_skWI_Yy9pAJQ1YhtBkEMicQ2vRxi3fFt9Tw_y4',
  });
}

export const CONFIG = config;
