import { render, staticRenderFns } from "./AjaxLoader.vue?vue&type=template&id=5e8dee30&scoped=true&"
import script from "./AjaxLoader.vue?vue&type=script&lang=js&"
export * from "./AjaxLoader.vue?vue&type=script&lang=js&"
import style0 from "./AjaxLoader.vue?vue&type=style&index=0&id=5e8dee30&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e8dee30",
  null
  
)

export default component.exports