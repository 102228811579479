export const messages = {
  menu: {
    login: 'Login',
    list: 'Shift list',
    about: 'About',
    logout: 'Logout',
    title: 'Shift evidence',
    clone: "Cloning of the shift '{0}'",
  },
  button: {
    approve: 'APPROVE',
    reject: 'Reject',
    login: 'Login',
    ok: 'OK',
    absence: 'ABS',
    back: 'Back',
    save: 'Save',
    cancel: 'Cancel',
    excuse: 'EXCUSE',
    send: 'SEND',
    clear: 'CLEAR',
    apply: 'APPLY',
    duplicate: 'CLONE',
    close: 'Close',
  },
  error: {
    shiftNotExists: "Shift {0} doesn't exist",
    notFound: 'Not found.',
    api404: 'Source not found.',
    api401: 'Token expired. Please login again.',
    api403: 'Permission denied. Unable to perform a required action.',
    timeFromAfterTimeTo: 'Shift start time shall preceed shift end time.',
    timeToBeforeTimeFrom: 'Shift end time shall follow shift end time.',
    login: 'Login failed. Incorrect login name or password.',
    shiftState: "The shift can't be saved twice with the same status.",
    system: 'System error',
    version: "Changes couldn't be saved, the shift was updated on background.",
    deleted: "Changes couldn't be saved, the shift was deleted on background.",
    closedPeriod: "Record cannot be updated. The period '{0}' was closed.",
  },
  message: {
    updated: 'Changes was saved.',
    duplicate: 'The shift was cloned.',
  },
  password: 'Password',
  email: 'E-mail',
  shift: {
    detail: 'Shift detail',
    from: 'from',
    to: 'to',
    days_worked: 'Days worked',
    worked: 'Work time',
    break: 'Break',
    state: {
      APPROVED: 'APPROVED',
      UNAPPROVED: 'UNAPPROVED',
      ABSENCE: 'ABSENCE',
      EXCUSED: 'EXCUSED',
    },
    state_cancel: 'cancel',
    state_actual: 'actual state',
  },
  list: {
    search: {
      term: 'Name',
      department: 'Department',
      dateFrom: 'From',
      dateTo: 'To',
      date: 'Shift date',
      orderBy: {
        title: 'Order by',
        dateAsc: 'By date ascending',
        dateDesc: 'By date descending',
        name: 'By name',
      },
    },
    head: {
      name: 'Name',
      time: 'Shift time',
      state: 'State',
    },
    filters: 'Filters',
    empty: 'No shifts was found',
    emptyByFilter: 'No shifts was found for the given filter. Try to change filter criteria for some results.',
  },
  loader: {
    loading: 'Loading ...',
  },
  contactForm: {
    subject: 'Message subject',
    text: 'Write your message here',
    send: 'Send',
    title: 'CONTACT FORM',
    To: 'To',
    success: 'Thank You for Your message. We respond as soon as possible.',
    error: {
      email: {
        empty: 'Subject cannot be empty',
        invalid: 'Subject is invalid',
      },
      text: {
        empty: 'Text cannot be empty',
        invalid: 'Text includes inappropriate words',
      },
    },
  },
  confirmUnapprove: {
    text: 'Do you really want to return the shift to „Unapproved“ state?',
    yes: 'YES',
    no: 'DO NOT RETURN',
  },
  customer: {
    name: {
      ha: 'HumanAgency s.r.o.',
      sks: 'SKS BUSINESS UNIT s.r.o.',
      si: 'Store Invent s.r.o.',
    },
  },
  resetPassword: {
    forgottenLink: 'Forgott password',
    password: 'New password',
    repeat: 'Confirm password',
    success: 'The password has been changed',
    noPasswordError: 'Password can not be empty',
    notEqualPasswordsError: 'Passwords in both fields must be the same',
    noEmailError: 'Email can not be empty',
    requestSent: 'Password reset link sent. Check your email.',
    email: 'E-mail',
    emailDescription: 'Email with reset password link will be sent to this address.',
    invalidEmailError: 'The specified string is not a valid email',
  },
  copyright: 'is powered by work agency software comming from ',
  datepicker: {
    ok: 'Ok',
    cancel: 'Cancel',
    clear: 'Clear',
  },
};
