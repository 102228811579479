<template>
    <b-row align-v="center" align-h="center" v-if="message" class="overlay">
        <b-col cols="10" class="inner">
            <p v-html="message.text" />

            <b-button v-if="message.onclick" variant="success" @click="message.onclick">{{ $t("button.ok") }}</b-button>
            <b-button v-else variant="success" @click="close">{{ $t("button.ok") }}</b-button>
        </b-col>
    </b-row>
</template>


<script>
export default {
    name: "success-modal",
    props: ['message'],
    methods: {
        close: function() {
            this.$ebus.$emit("msg:clear");
            this.$ebus.$emit("modal:close");
        }
    }
};
</script>


<style scoped lang="scss">
@import "src/scss/overlay";

</style>
